<template>
    <VolBox v-model="model" ref="volbox" :lazy="true" title="选择员工" :height="540" :width="800" :padding="15">
        <div>
            <div class="toolbar">
                <div class="container">
                    <div class="select-tree">
                        <div class="detail_head">
                            <el-checkbox v-show="!is_sigle && filterText && filterText.length > 0"
                                @change="search_check" label="全选" style="margin-right: 5px" />
                            <el-input :class="is_sigle ? 'single' : 'multiple'" v-model="filterText"
                                placeholder="请输入搜索关键字" />
                            <el-select multiple v-show="!is_sigle" style="width: 40%; margin-left: 1%;"
                                v-model="tagValue" clearable placeholder="员工分组标签选择" @change="EmpTagChage">
                                <el-option v-for="item in tagOptions" :key="item.key" :label="item.value"
                                    :value="item.key" />
                            </el-select>
                        </div>

                        <el-tree v-loading="tree_loading" :default-expanded-keys="default_expanded_keys"
                            :data="treeData" ref="treeRef" node-key="id" style="max-width: 600px" :props="treeProps"
                            show-checkbox :filter-node-method="filterNode" @check="treeCheck" :check-strictly="is_sigle"
                            @node-click="handleNodeClick">


                            <template #default="{ node, data }">

                                <span v-if="data.isDeptNode">{{ node.label }}</span>

                                <el-tag v-else class="emp_title_position" type="primary"
                                    @click="() => { console.log(node); }">
                                    <el-tag type="success" effect="dark">
                                        {{ data.epm.empName }}
                                    </el-tag>
                                    {{ data.posName }}
                                </el-tag>
                            </template>


                        </el-tree>
                    </div>
                    <div class="select-table" ref="scrollContainer">
                        <div class="detail_head">
                            <span>已选择：</span> <el-text class="mx-1" type="success">{{ tableData.length }}</el-text>
                            <el-button style="margin-left: 10px" key="清空已选" @Click="clean_click" type="danger" text>
                                清空
                            </el-button>
                        </div>
                        <div>
                            <p v-for="tag in tableData">
                                <el-tag class="emp_title" type="primary" :key="tag" closable @close="handleClose(tag)">
                                    <el-tag :key="tag.deptName" type="success" effect="dark">
                                        {{ tag.empName }}
                                    </el-tag>
                                    {{ `${tag.deptName}-${tag.posName}` }}
                                </el-tag>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                <el-button size="medium" @click="closeEmpBox(tableData)"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>

<script lang="ts" setup>
    import VolBox from "@/components/basic/VolBox.vue";
    import type { FilterNodeMethodFunction, TreeNodeData, TreeKey } from 'element-plus/es/components/tree/src/tree.type';
    import { ElMessage, ElTree } from 'element-plus';
    import { inject, ref, onBeforeMount, onMounted, watch, nextTick, defineProps, withDefaults, reactive } from "vue";
    import http from '@/api/http';
    interface Props {
        title: string,
        default_checked_keys: string[],
        default_expanded_keys: string[]
        is_sigle: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        title: "员工列表",
        default_checked_keys: () => [],
        default_expanded_keys: () => ['12']
    })

    const emit = defineEmits<{
        (e: 'EmpCallBack', emp: Employee[]): void
    }>();

    /**
     * 树节点
     */
    interface EmpTreeNode {
        id: string;
        name: string;
        parentId: string;
        isDeptNode: boolean; //是否为部门节点
        posName?: string;
        epm?: Employee;
        children?: EmpTreeNode[];
    }

    /**
     * 部门
     */
    interface Department {
        id: number;
        deptName: string;
        parentId: string;
    }
    /**
     * 员工
     */
    interface Employee {
        deptID: string;
        empID: number;
        empName: string;
        posName: string;
        deptName: string;
        primaryDeptId: number;
    }

    const tagValue = ref('')

    const tagOptions = ref<any[]>([])
    /**
     * 树节点
     */
    const treeData = ref<EmpTreeNode[]>([]);

    /**
     * 树节点过滤文本
     */
    const filterText = ref('')
    /**
     * 树控件引用
     */
    const treeRef = ref<InstanceType<typeof ElTree>>()
    /**
     * 树空间属性设置
     */
    const treeProps = { label: 'name', children: 'children' }

    /**
     * 右边列表数据
     */
    const tableData = ref<Employee[]>([]);

    const scrollContainer = ref<any>(null);

    const volbox = ref<any>(null);


    /**
     * 过滤树节点
     * @param value 关键字
     * @param data 节点数据
     * @param child 子节点
     */
    const filterNode: FilterNodeMethodFunction = (value, data, child) => {
        if (!value) return true
        return data.name.includes(value)
    };


    const treeCheck = (currentNode: EmpTreeNode, checkedStatus: { checkedKeys: TreeKey[], checkedNodes: TreeNodeData[] }) => {
        tableData.value = [];
        if (props.is_sigle) {
            if (currentNode.isDeptNode) {
                treeRef.value?.setCheckedKeys([], false);
                ElMessage.warning("请选择单个员工");
                return;
            }

            treeRef.value?.setCheckedKeys([currentNode.id], false)
            handleAddData(currentNode)

        } else {
            let selectedNode = checkedStatus.checkedNodes as EmpTreeNode[];
            selectedNode.filter(m => !m.isDeptNode).forEach(m => {
                handleAddData(m)
            });
        }
    }

    /**
     * tag 删除事件
     * @param tag 触发的tag
     */
    const handleClose = (tag: Employee) => {
        let index = tableData.value.findIndex(employee => employee.empID === tag.empID);
        if (index !== -1) {
            tableData.value.splice(index, 1);
        }
        treeRef.value!.setChecked(`emp_${tag.empID}`, false, false)
    }
    /**
     * 处理数据添加的函数
     * @param data 
     */
    const handleAddData = (data: EmpTreeNode) => {
        if (data.epm) {
            tableData.value.push({
                deptID: "0",
                empID: data.epm ? data.epm.empID : 0,
                empName: data.epm ? data.epm.empName : "",
                posName: data.epm ? data.epm.posName : "",
                deptName: data.epm ? data.epm.deptName : "",
                primaryDeptId: data.epm ? data.epm.primaryDeptId : 0
            });

            if (scrollContainer.value) {
                nextTick(() => {
                    scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight;
                });
            }
        }
    };



    /**
     * 请求部门数据
     */
    const fetchDepartments = async (): Promise<Department[] | undefined> => {
        try {
            const result = await http.post<Department[]>(
                '/api/department/getUserChildDepts',
                {},
                false
            );
            return result.data;

        } catch (error) {
            console.error('请求失败:', error);
            ElMessage.error('请求失败，请重试');
        }
    }

    /**
     * 请求员工数据
     */
    const fetchEmployees = async (): Promise<Employee[] | undefined> => {
        try {
            const result = await http.postPageData<Employee>(
                'api/Sys_Employee/GetSelectorEmployee',
                {
                    rows: 20000,
                    page: 1,
                    // wheres: '[{"name":"status","value":"1,2,3" }]'
                }
            );
            return result.rows;
        } catch (error) {
            console.error('请求失败:', error);
            ElMessage.error('请求失败，请重试');
        }
    }

    const fetchDictionary = async () => {
        let result = await http.postAny<any[]>(
            '/api/Sys_Dictionary/GetVueDictionary',
            ["员工分组标签"]);
        if (result)
            tagOptions.value = result[0].data
    }

    const empGroupMap: { [key: string]: string[] } = {};

    const EmpTagChage = (tagKeys: string[]) => {

        let requestKeys = tagKeys.filter(m => Object.keys(empGroupMap).indexOf(m) < 0);
        if (requestKeys && requestKeys.length > 0) {
            http.get<any[]>(`/api/OA_EmployeeTag/GetEmpIds?tagId=${requestKeys[0]}`).then((rut) => {
                empGroupMap[requestKeys[0]] = rut;
                handlerEmpGroup(tagKeys);
            });
        } else {
            handlerEmpGroup(tagKeys);
        }
    }

    const handlerEmpGroup = (tagKeys: string[]) => {
        tableData.value = [];
        tagKeys.forEach(tagKey => {
            let rut = empGroupMap[tagKey]
            treeRef.value?.setCheckedKeys(rut);
            rut.forEach(element => {
                if (empMap[element]) {
                    handleAddData(empMap[element]);
                }
            });
        });
    }



    // 创建一个 map 用于存储部门节点
    const empMap: { [key: string]: EmpTreeNode } = {};
    /**
     * 组装树
     */
    const buildEmpTree = (departments: Department[], employees: Employee[]) => {

        // 创建一个 map 用于存储部门节点
        const deptMap: { [key: string]: EmpTreeNode } = {};

        // 初始化部门节点
        departments.forEach(dept => {
            deptMap[dept.id] = {
                id: dept.id.toString(),
                name: dept.deptName,
                parentId: dept.parentId.toString(),
                isDeptNode: true,
                children: []
            };
        });

        // 将员工添加到相应的部门节点下
        employees.forEach(emp => {
            if (emp.primaryDeptId) {
                const deptNode = deptMap[emp.primaryDeptId.toString()];
                if (deptNode) {
                    deptNode.children = deptNode.children ? deptNode.children : [];
                    let childData = {
                        id: `emp_${emp.empID.toString()}`,
                        name: `${emp.empName}-${emp.posName}`,
                        parentId: emp.primaryDeptId.toString(),
                        posName: emp.posName,
                        epm: emp,
                        isDeptNode: false
                    };
                    let childId = `emp_${emp.empID.toString()}`;
                    deptNode.children.push(childData);
                    empMap[childId] = childData;
                }
            }


        });



        Object.keys(deptMap).forEach(key => {
            const node = deptMap[parseInt(key, 10)];
            if (node.parentId === '0') {
                treeData.value.push(node);
            } else {
                const parentNode = deptMap[parseInt(node.parentId)];
                if (parentNode) {
                    parentNode.children = parentNode.children ? parentNode.children : [];
                    parentNode.children.push(node);
                }
            }
        });
    }

    /**
     * 树loading
     */
    const tree_loading = ref<boolean>(false);
    /**
     * 请求数据
     */
    const fetchData = async () => {
        try {
            tree_loading.value = true;
            const [departments, employees] = await Promise.all([fetchDepartments(), fetchEmployees()]);
            tree_loading.value = false;
            // 调用 buildEmpTree 并返回结果
            if (departments && employees) {
                buildEmpTree(departments, employees);
                initTree(props.default_checked_keys)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    /**
     * 生命周期挂载事件
     */
    onBeforeMount(() => {

    });
    onMounted(() => {
        fetchData();
        fetchDictionary();
    })

    /**
     * 搜索词监听
     */
    watch(filterText, (val) => {
        treeRef.value!.filter(val)
    });

    // 监听 default_checked_keys 的变化
    watch(() => props.default_checked_keys, (newValue, oldValue) => {
        nextTick(() => {
            tagValue.value = '';
            filterText.value = '';
            setTimeout(() => {
                initTree(newValue);

            }, 100);
        });
    }, { deep: true, immediate: true });

    const clean_click = () => {
        clearTreeModule();
        treeRef.value?.setCheckedKeys([], false)
        tableData.value = [];
        tagValue.value = '';
        filterText.value = '';
    }

    // 清空树
    const clearTreeModule = () => {
        const node = treeRef.value?.getNode(12).parent;
        const clearTree = (node: any) => {
            if (node.childNodes && node.childNodes.length > 0) {
                node.childNodes.map((val: any) => {
                    val.checked = false;
                    clearTree(val);
                });
            }
        };
        clearTree(node);
    };


    const initTree = (keys: string[]) => {
        treeRef.value?.setCheckedKeys([], false)
        treeRef.value?.setCheckedKeys(keys, false)

        tableData.value = [];
        keys.forEach(m => {
            if (empMap[m])
                handleAddData(empMap[m]);

        });
    }

    /**
     * 是否显示弹窗
     */
    const model = ref<boolean>(false);
    const addRow = () => {
        if (!tableData || tableData.value.length == 0) {
            ElMessage.warning("请选择行数据");
            return;
        }
        emit('EmpCallBack', tableData.value)
    }
    const closeEmpBox = (item?: any) => {
        volbox.value.handleClose();
    }


    // 获取搜索结果的所有节点
    const getFilteredNodes = () => {
        const allNodes: any = []
        const traverse = (node: any) => {
            if (node.visible) {
                allNodes.push(node.data)
                node.childNodes.forEach(traverse)
            } else if (node.visible) {
                allNodes.push(node.data)
            }
        }

        treeRef.value?.root.childNodes.forEach(traverse)
        return allNodes
    }


    const search_check = (value: any) => {
        const filteredNodes = getFilteredNodes();
        if (!filteredNodes) return;
        let searchNode = filteredNodes.filter((node: EmpTreeNode) => !node.isDeptNode);
        let keys = searchNode.map((node: EmpTreeNode) => node.id);
        if (value) {
            searchNode.forEach((m: EmpTreeNode) => {
                handleAddData(m);
            });
            treeRef.value?.setCheckedKeys(keys, false)
        } else {
            searchNode.forEach((m: EmpTreeNode) => {
                if (m?.epm?.empID)
                    tableData.value = tableData.value.filter(item => item.empID != m?.epm?.empID);
            });
            const kyeset = new Set(keys);
            let checkedItems = treeRef.value?.getCheckedKeys();
            let checkedItems_new = checkedItems?.filter(item => !kyeset.has(item));
            if (checkedItems_new)
                treeRef.value?.setCheckedKeys(checkedItems_new, false)
        }

    }

    const handleNodeClick = (data: any, node: any) => {

        if (!data.isDeptNode) {
            treeRef.value?.setChecked(data.id, !node.checked, false)
            //手动触发check事件
            treeCheck(data, {
                checkedKeys: treeRef.value?.getCheckedKeys() ?? [],
                checkedNodes: treeRef.value?.getCheckedNodes() ?? []
            })

        }
    }

    // 通过 defineExpose 显示暴露 childData
    defineExpose({
        tableData
    });
</script>



<style lang="scss" scoped>
    $left-width: 430px;

    @mixin flex-container {
        display: flex;
    }

    .container {
        @include flex-container;

        .select-tree {
            flex: 0 0 $left-width;
            max-height: 450px;
            overflow: auto;
            width: $left-width; // 明确设置宽度
            position: relative;
        }

        .select-table {
            flex: 1;
            height: 100%;
            max-height: 450px;
            overflow: auto;
        }
    }

    .emp_title {
        padding: 0 0 0 0;
    }

    .emp_title_position {
        padding: 0 8px 0 0;
    }


    .detail_head {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: white;
        padding: 10px;
        width: 100%;
    }


    .multiple {
        width: 40%;
        margin-right: 1%;
    }

    .single {
        width: 100%;
    }
</style>