<template>
    <VolBox v-model="model" :lazy="true" title="选择培训课程" :height="520" :width="800" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="课程名称" style="width: 200px" v-model="courseName" />
            <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i class="el-icon-search" ></i>搜索</el-button>
        </div>

        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="coursetable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="false"
            :height="400" :url="url" :index="true" :single="true" :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="mini" type="primary" @click="addRow()"><i class="el-icon-plus"></i>添加选择的数据</el-button>
                <el-button size="mini" @click="model = false;closeCourseBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";
export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
    },
    props: {
        selectRow: Object
    },
    data() {
        return {
            model: false,
            defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            courseName: "", //查询条件字段
            url: "api/Tra_Course/getPageData",//加载数据的接口
            columns: [
                { field: "CourseID", title: "CourseID", width: 90, hidden: true },
                { field: "Name", title: "课程名称", width: 120 },
                {field:'Type',title:'课程分类',type:'int',bind:{ key:'培训课程分类',data:[]},width:110,require:true,align:'left'},
                { field: "DeptID", title: "所属部门", width: 120, type: 'int', bind: { key: 'dept-tree', data: [] } },
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.handleClick(row)
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            pagination: {}, //分页配置，见voltable组件api
        };
    },
    methods: {
        load() {
            //点击搜索
            this.$nextTick(() => {
                this.$refs.coursetable.load();
            });
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.coursetable.load();
            });
        },
        search() {
            //点击搜索
            this.$refs.coursetable.load();
        },
        addRow() {
            var rows = this.$refs.coursetable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.handleClick(rows[0])
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
            //查询前，设置查询条件
            if (this.courseName) {
                params.wheres = [{ name: "Name", value: this.courseName,displayType:"like" }];
            }
            return true;
        }
    },
    emits: ["CourseCallBack","CloseCourseBox"],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('CourseCallBack', item)
        }
        const closeCourseBox = (item) => {
            emit('CloseCourseBox', item)
        }
        return {
            handleClick,
            closeCourseBox,
        }
    },
};
</script>
  