import detailMethods from './detailMethods.js';
//业务处理方法,全部可以由开发覆盖
import serviceFilter from './serviceFilter.js';
let methods = {
  //当添加扩展组件gridHeader/gridBody/gridFooter及明细modelHeader/modelBody/modelFooter时，
  //如果要获取父级Vue对象,请使用此方法进行回调
  parentCall(fun) {
    if (typeof fun != 'function') {
      return console.log('扩展组件需要传入一个回调方法才能获取父级Vue对象');
    }
    fun(this);
  },
  getCurrentAction() {
    if (this.currentReadonly) {
      return '';
    }
    return '--' + (this.currentAction == this.const.ADD ? '新增' : '编辑');
  },
  quickSearchKeyPress($event) {
    //查询字段为input时，按回车查询
    if ($event.keyCode == 13) {
      if (this.searchFormFields[this.singleSearch.field] != '') {
        this.search();
      }
    }
  },
  getButtons() {
    //生成ViewGrid界面的操作按钮及更多选项
    let searchIndex = this.buttons.findIndex((x) => {
      return x.value == 'Search';
    });
    //添加高级查询
    let hasOneFormItem =
      this.searchFormOptions.length == 1 &&
      this.searchFormOptions[0].length == 1;
    if (searchIndex != -1 && !hasOneFormItem) {
      this.buttons.splice(searchIndex + 1, 0, {
        icon: this.fiexdSearchForm ? 'el-icon-refresh-left' : 'el-icon-search',
        name: this.fiexdSearchForm ? '重置' : '高级查询',
        plain: true,
        type: this.buttons[searchIndex].type,
        onClick: () => {
          if (this.fiexdSearchForm) {
            return this.resetSearch();
          }
          this.searchBoxShow = !this.searchBoxShow;
        }
      });
    }
    if (hasOneFormItem) {
      this.fiexdSearchForm = false;
    }
    this.maxBtnLength += searchIndex == -1 ? 0 : 1;
    // if (this.buttons.length <= this.maxBtnLength) {
    //   return this.buttons;
    // }
    // let btns = this.buttons.slice(0, this.maxBtnLength);
    // btns[this.maxBtnLength - 1].last = true;
    // return btns;
  },
  extendBtn(btns, source) {
    //btns权限按钮，source为扩展按钮
    if (!btns || !(source && source instanceof Array)) {
      return;
    }
    //source通过在表的扩展js文件中buttons对应按钮的属性index决定按钮所放位置
    source.forEach((x) => {
      //通过按钮的Index属性，放到指定的位置
      btns.splice(x.index == undefined ? btns.length : x.index, 0, x);
    });
    // if (this.extend.buttons.view) {
    //     this.extend.buttons.view.forEach((x) => {
    //通过按钮的Index属性，放到指定的位置
    //         this.buttons.splice(x.index == undefined ? this.buttons.length : x.index, 0, x);
    //     })
    // }
  },
  initBoxButtons() {
    //初始化ViewGird与弹出框/明细表按钮
    let path = this.$route.path;
    //通过菜单获取用户所对应菜单需要显示的按钮
    let permissionButtons = this.permission.getButtons(
      path,
      null,
      this.extend.tableAction,
      this.table.name
    );
    if (permissionButtons) {
      //2020.03.31添加深拷贝按钮组
      permissionButtons.forEach((p) => {
        let _obj = {};
        for (const key in p) {
          _obj[key] = p[key];
        }
        this.buttons.push(_obj);
      });
      // this.buttons.push(...permissionButtons);
    }
    if (!this.extend) {
      this.extend = {};
    }
    if (!this.extend.buttons) {
      this.extend.buttons = {};
    }
    //查询界面扩展按钮(扩展按钮可自行通过设置按钮的Index属性显示到具体位置)
    if (this.extend.buttons.view) {
      this.extendBtn(this.buttons, this.extend.buttons.view);
    }

    //弹出框按钮
    let boxButtons = [];

    let saveBtn = this.buttons.some((x) => {
      if (
        x.value &&
        (x.value.toLowerCase() == this.const.ADD.toLowerCase() ||
          x.value.toLowerCase() == this.const.EDIT.toLowerCase())
      )
        return true;
    });
    let auditBtn = this.buttons.some((x) => {
      if (
        x.value &&
        (x.value.toLowerCase() == this.const.AUDIT.toLowerCase())
      )
        return true;
    });
    this.currentReadonly = !saveBtn;
    //从表表格操作按钮
    let detailGridButtons = {
      name: '刷新',
      type: 'info',
      icon: 'el-icon-refresh',
      onClick() {
        //如果明细表当前的状态为新建时，禁止刷新
        if (this.currentAction == this.const.ADD) {
          return;
        }
        this.refreshRow();
      }
    };

    let importExcel = this.buttons.some((x) => {
      if (x.value == this.const.IMPORT) return true;
    });
    //如果有导入权限,则需要初始化导入组件
    if (importExcel) {
      this.upload.url = this.getUrl(this.const.IMPORT);
      //定义下载模板的文件名
      this.upload.template.fileName = this.table.cnName;
      //定义下载模板的Url路径
      this.upload.template.url =
        this.http.ipAddress + this.getUrl(this.const.DOWNLOADTEMPLATE, true);
    }

    // disabled
    //如果当前角色没有编辑或新建功能，查看明细时字段设置全部只读
    //只有明细表，将明细表也设置为不可能编辑，并且不显示添加行、删除行
    if (!saveBtn) {
      this.editFormOptions.forEach((row) => {
        row.forEach((x) => {
          x.disabled = true;
        });
      });
      if (this.editFormFieldsAfterDetail) {
        this.editFormOptionsAfterDetail.forEach((row) => {
          row.forEach((x) => {
            x.disabled = true;
          });
        });
      }
      //没有新增编辑权限的，弹出框都设置为只读
      this.detail.columns.forEach((column) => {
        if (column.hasOwnProperty('edit')) {
          column.readonly = true;
          // row['edit'] = false;
        }
      });
      this.details.forEach(item => {
        item.columns.forEach((column) => {
          if (column.hasOwnProperty('edit')) {
            column.readonly = true;
          }
        });
      })
      //弹出框扩展按钮
      this.extendBtn(boxButtons, this.extend.buttons.box);
      //弹出弹框按钮(2020.04.21),没有编辑或新建权限时，也可以通过buttons属性添加自定义弹出框按钮
      this.boxButtons.push(...boxButtons);
      this.detailOptions.buttons.push(detailGridButtons);
      this.detailOptions.buttons.forEach((button) => {
        if (!button.hasOwnProperty('hidden')) {
          button.hidden = false;
        }
      });
      //弹出框扩展明细表按钮
      this.extendBtn(this.detailOptions.buttons, this.extend.buttons.detail);

      return boxButtons;
    }

    this.detailOptions.edit = true;
    boxButtons.push(
      ...[
        {
          name: '保 存',
          icon: 'el-icon-check',
          type: 'primary',
          disabled: false,
          value: 'save',
          onClick() {
            this.save();
          }
        }
        // {
        //   name: '重 置',
        //   icon: 'el-icon-refresh-right',
        //   type: 'primary',
        //   disabled: false,
        //   onClick() {
        //     this.resetEdit();
        //   }
        // }
      ]
    );
    if (this.columns.filter(t => t.field == 'AuditStatus').length > 0) {
      boxButtons.push(
        ...[
          {
            name: '提 交',
            icon: 'el-icon-s-promotion',
            type: 'warning',
            disabled: false,
            value: 'submit',
            onClick() {
              this.save(1);
            }
          }
        ]
      );
    }
    //从表表格操作按钮
    this.detailOptions.buttons.push(
      ...[
        {
          name: '添加',
          icon: 'el-icon-plus',
          type: 'primary',
          hidden: false,
          plain: true,
          onClick() {
            this.addRow();
          }
        },
        {
          type: 'danger',
          plain: true,
          name: '删除',
          hidden: false,
          icon: 'el-icon-delete',
          onClick() {
            this.delRow();
          }
        },
        //2022.01.08增加明细表导入导出功能
        //注意需要重写后台明细表接口的导入与下载模板、导出的权限,Sys_DictionaryListController.cs/SellOrderListController.cs
        {
          type: 'danger',
          plain: true,
          name: '导入',
          value: 'import',
          hidden: true,
          icon: 'el-icon-upload2',
          onClick() {
            this.upload.url = `${this.http.ipAddress}api/${this.detail.table}/${this.const.IMPORT}?table=1`;
            this.upload.template.url = `${this.http.ipAddress}api/${this.detail.table}/${this.const.DOWNLOADTEMPLATE}`;
            // 定义下载模板的文件名
            this.upload.template.fileName = this.detail.cnName;
            this.upload.excel = true;
          }
        },
        {
          type: 'danger',
          plain: true,
          name: '导出',
          value: 'export',
          icon: 'el-icon-download',
          hidden: true,
          onClick() {
            this.export(true);
          }
        }
      ]
    );
    // this.detailOptions.buttons.forEach((button) => {
    //   if (button.hasOwnProperty('hidden')) {
    //     button.hidden = false;
    //   }
    // });
    //弹出框扩展按钮
    this.extendBtn(boxButtons, this.extend.buttons.box);

    //弹出框扩展明细表按钮
    this.detailOptions.buttons.push(detailGridButtons);
    this.extendBtn(this.detailOptions.buttons, this.extend.buttons.detail);

    //弹出弹框按钮
    this.boxButtons.push(...boxButtons);
  },
  onClick(click) {
    click.apply(this);
  },
  changeDropdown(btnName, v1) {
    let button = this.buttons.filter((x) => {
      return x.name == btnName;
    });
    if (button && button.length > 0) {
      button[0].onClick.apply(this);
    }
  },
  emptyValue(value) {
    if (typeof value == 'string' && value.trim() === '') {
      return true;
    }
    if (value instanceof Array && !value.length) {
      return true;
    }
    return value === null || value === undefined || value === '';
  },
  getSearchParameters() {
    //获取查询参数
    // 2020.09.11增加固定查询表单,如果设置固定了查询表单，点击查询时，不再关闭
    if (!this.fiexdSearchForm) {
      this.searchBoxShow = false;
    }

    let query = { wheres: [] };
    for (const key in this.searchFormFields) {
      let value = this.searchFormFields[key];
      if (this.emptyValue(value)) continue;

      if (typeof value == 'number') {
        value = value + '';
      }
      var keyOption = this.getSearchOption(key)
      let displayType = this.getSearchItem(key);
      if(displayType == "slider" && keyOption.reset){
        continue
      }

      //联级只保留选中节点的最后一个值
      if (displayType == 'cascader') {
        //查询下面所有的子节点，如：选中的是父节点，应该查询下面所有的节点数据--待完
        value = value.length ? value[value.length - 1] + '' : '';
      }
      //2021.05.02增加区间查询
      if (
        typeof value == 'string' ||
        ['date', 'datetime', 'range'].indexOf(displayType) == -1
      ) {
        query.wheres.push({
          name: key,
          value:
            typeof value == 'string' ? (value + '').trim() : value.join(','),
          displayType: displayType
        });
        continue;
      }
      for (let index = 0; index < value.length; index++) {
        if (!this.emptyValue(value[index])) {
          query.wheres.push({
            name: key,
            value: (value[index] + '').trim(),
            displayType: (() => {
              if (['date', 'datetime', 'range'].indexOf(displayType) != -1) {
                return index ? 'lessorequal' : 'thanorequal';
              }
              return displayType;
            })()
          });
        }
      }
    }
    return query;
  },
  search() {
    //查询
    // let query = this.getSearchParameters();
    // this.$refs.table.load(query, true);
    this.$refs.table.load(null, true);
  },
  loadTableBefore(param, callBack) {
    //查询前设置查询条件及分页信息
    let query = this.getSearchParameters();
    if (query) {
      param = Object.assign(param, query);
    }

    if (this.$route.query.viewflow && this.$route.query.id) {
      param.wheres.push({
        name: this.table.key,
        value: this.$route.query.id
      });
    }
    // if (this.isViewFlow() && data && data.length) {
    //   let query = JSON.parse(JSON.stringify(this.$route.query));
    //   query.viewflow = 0;
    //   this.$router.replace({ path: this.$route.path, query: query });
    //   this.$nextTick(() => {
    //     this.getWorkFlowSteps(data[0]);
    //   });
    // }
    let status = this.searchBefore(param);
    callBack(status);
  },

  loadTableAfter(data, callBack, result) {
    //查询后
    //2020.10.30增加查询后返回所有的查询信息
    let status = this.searchAfter(data, result);
    callBack(status);
    //自动弹出框审批详情
  },
  loadDetailTableBefore(param, callBack, tableIndex) {
    //明细查询前
    //新建时禁止加载明细
    if (this.currentAction == this.const.ADD) {
      callBack(false);
      return false;
    }
    let status = this.searchDetailBefore(param, tableIndex);
    callBack(status);
  },
  loadDetailTableAfter(data, callBack) {
    //明细查询后
    let status = this.searchDetailAfter(data);
    callBack(status);
  },
  loadDetailTableFinal(data, tableIndex) {
    //明细数据加载到列表完成后
    this.searchDetailFinal(data, tableIndex);
  },
  getSearchOption(field) {
    //获取查询的参数
    let data;
    this.searchFormOptions.forEach(items => {
      items.forEach(item => {
        if (item.field == field) {
          data = item
        }
      })
    })
    return data
  },
  getSearchItem(field) {
    //获取查询的参数
    let data;
    for (let index = 0; index < this.searchFormOptions.length; index++) {
      if (data) return data.type;
      const item = this.searchFormOptions[index];
      data = item.find((x) => {
        return x.field == field;
      });
    }

    return (data || {}).type;
  },
  resetSearch() {
    //重置查询对象
    this.resetSearchForm();
    //2020.10.17增加重置后方法
    this.resetSearchFormAfter && this.resetSearchFormAfter();
  },
  resetEdit() {
    //重置编辑的数据
    let isEdit = this.currentAction != this.const.ADD;
    //重置之前
    if (!this[isEdit ? 'resetUpdateFormBefore' : 'resetAddFormBefore']()) {
      return;
    }
    let objKey = {};
    //编辑状态下,不需要重置主键,创建时间创建人
    if (isEdit) {
      objKey[this.table.key] = this.editFormFields[this.table.key];
    }
    this.resetEditForm(objKey);
    //重置之后

    if (!this[isEdit ? 'resetUpdateFormAfter' : 'resetAddFormAfter']()) {
      return;
    }
  },
  resetSearchForm(sourceObj) {
    //重置查询表
    this.resetForm('searchForm', sourceObj);
  },
  resetEditForm(sourceObj) {
    if (this.hasDetail && this.$refs.detail) {
      // this.$refs.detail.rowData.splice(0);
      this.$refs.detail.reset();
    }
    //重置多子表tabs，默认显示第一个，同时重置表单
    if (this.details != null && this.details.length > 1) {
      this.$refs.detailTabs.currentName = '0';
      this.details.forEach((item, index) => {
        item.detailOptions.delKeys = []
        var detail = this.$refs["detail" + item.tableIndex][0]
        detail.reset()
      })
    }
    this.resetForm('form', sourceObj);
    if (this.$refs.form && this.$refs.form.$refs.volform) {
      setTimeout(() => {
        this.$refs.form.$refs.volform.clearValidate();
      }, 100);
    }
    if (this.$refs.formAfterDetail) {
      this.resetForm('formAfterDetail', sourceObj);
      if (this.$refs.formAfterDetail.$refs.volform) {
        setTimeout(() => {
          this.$refs.formAfterDetail.$refs.volform.clearValidate();
        }, 100);
      }
    }
  },
  getKeyValueType(formData, isEditForm) {
    try {
      let keyLeft = (isEditForm ? 'e' : 's') + '_b_';
      formData.forEach((item) => {
        item.forEach((x) => {
          if (this.keyValueType.hasOwnProperty(keyLeft + x.field)) {
            return true;
          }
          let data;
          if (x.type == 'switch') {
            this.keyValueType[x.field] = 1;
          } else if (x.bind && x.bind.data) {
            data = x.bind.data;
          } else if (x.data) {
            if (x.data instanceof Array) {
              data = x.data;
            } else if (x.data.data && x.data.data instanceof Array) {
              data = x.data.data;
            }
          }
          if (
            data &&
            data.length > 0 &&
            !this.keyValueType.hasOwnProperty(x.field)
          ) {
            this.keyValueType[x.field] = data[0].key;
            this.keyValueType[keyLeft + x.field] = x.type;
          }
        });
      });
    } catch (error) {
      console.log(error.message);
    }
  },
  resetForm(formName, sourceObj) {
    //   return;
    //重置表单数据
    if (this.$refs[formName]) {
      this.$refs[formName].reset();
    }

    if (!sourceObj) return;
    let form, keyLeft;
    if (formName == 'searchForm') {
      form = this.searchFormFields;
      keyLeft = 's' + '_b_';
    } else if (formName == "formAfterDetail") {
      form = this.editFormFieldsAfterDetail
      keyLeft = 'e' + '_b_';
    } else {
      form = this.editFormFields;
      keyLeft = 'e' + '_b_';
    }
    //获取数据源的data类型，否则如果数据源data的key是数字，重置的值是字符串就无法绑定值
    if (!this.keyValueType._dinit) {
      this.getKeyValueType(this.editFormOptions, true);
      this.getKeyValueType(this.searchFormOptions, false);
      if (formName == "formAfterDetail") {
        this.getKeyValueType(this.editFormOptionsAfterDetail, true);
      }
      this.keyValueType._dinit = true;
    }
    var _cascaderParentTree;
    for (const key in form) {
      if (sourceObj.hasOwnProperty(key)) {
        let newVal = sourceObj[key];
        let kv_type = this.keyValueType[keyLeft + key];

        if (
          kv_type == 'selectList' ||
          kv_type == 'checkbox' ||
          kv_type == 'cascader' ||
          kv_type == 'treeSelect'
        ) {
          // 2020.05.31增加iview组件Cascader
          // 2020.11.01增加iview组件Cascader表单重置时查询所有的父节点
          if (kv_type == 'cascader' || kv_type == 'treeSelect') {
            var treeDic = this.dicKeys.find((dic) => {
              return dic.fileds && dic.fileds.indexOf(key) != -1;
            });

            if (treeDic && treeDic.orginData && treeDic.orginData.length) {
              let keyIsNum = typeof treeDic.orginData[0].id == 'number';

              if (kv_type == 'cascader') {
                newVal = keyIsNum ? newVal * 1 || 0 : newVal + '';
                if (kv_type == 'cascader') {
                  _cascaderParentTree = this.base.getTreeAllParent(
                    newVal,
                    treeDic.orginData
                  );
                  if (_cascaderParentTree) {
                    newVal = _cascaderParentTree.map((x) => {
                      return x.id;
                    });
                  }
                }
              } else {
                if (newVal === null || newVal === undefined) {
                  newVal = [];
                } else if (typeof newVal == 'string') {
                  newVal = newVal.split(',');
                }
                if (keyIsNum) {
                  if (Array.isArray(newVal)) {
                    newVal = newVal.map((x) => {
                      return x * 1 || 0;
                    });
                  }
                } else if (typeof newVal == 'number') {
                  newVal = [newVal + ''];
                }
              }
            } else {
              newVal = [newVal];
            }
          } else if (
            newVal != '' &&
            newVal != undefined &&
            typeof newVal == 'string'
          ) {
            newVal = newVal.split(',');
          } else if (kv_type == 'checkbox') {
            newVal = [];
          }
        } else if (
          this.keyValueType.hasOwnProperty(key) &&
          typeof this.keyValueType[key] == 'number' &&
          newVal * 1 == newVal
        ) {
          newVal = newVal * 1;
        } else {
          if (newVal == null || newVal == undefined) {
            newVal = '';
          } else if (this.numberFields.indexOf(key) != -1) {
            newVal = newVal * 1 || 0;
          } else {
            newVal += '';
          }
        }
        if (newVal instanceof Array) {
          if (form[key]) {
            form[key] = [];
          }
          form[key] = newVal;
        } else {
          form[key] = newVal;
        }
      } else {
        form[key] = form[key] instanceof Array ? [] : '';
      }
    }
  },
  onBtnClick(param) {
    this[param.method](param.data);
  },
  refresh() {
    //刷新
    this.search();
    // this.$refs.table.load();
  },
  saveBefore(formData) {
    return true;
  },
  saveAfter(formData, result) {
    return true;
  },
  save(type) {
    //新增或编辑时保存
    // if (!this.$refs.form.validate()) return;
    if (!this.saveClick(type)){
      return;
    }
    if(type == 0 && !this.saveVerify){
      //新加判断，如果是保存并且设置了不验证表单，直接执行保存操作
      this.saveExecute(type);
      return;
    }
    this.$refs.form.validate((result) => {
      if (result) {
        if (this.$refs.formAfterDetail) {
          this.$refs.formAfterDetail.validate((result) => {
            if (result) {
              this.saveExecute(type);
            }
          });
        }
        else {
          this.saveExecute(type);
        }
      }
    });
  },
  async saveExecute(type) {
    let editFormFields = {};
    if (this.editFormFieldsAfterDetail) {
      Object.assign(this.editFormFields, this.editFormFieldsAfterDetail)
    }
    //上传文件以逗号隔开
    for (const key in this.editFormFields) {
      if (
        this.uploadfiled &&
        this.uploadfiled.length > 0 &&
        this.uploadfiled.indexOf(key) != -1 &&
        this.editFormFields[key] instanceof Array
      ) {
        let allPath = this.editFormFields[key].map((x) => {
          return x.path;
        });
        editFormFields[key] = allPath.join(',');
      } else if (typeof this.editFormFields[key] == 'function') {
        try {
          editFormFields[key] = this.editFormFields[key]();
        } catch (error) { }
      } else {
        //2021.05.30修复下拉框清除数据后后台不能保存的问题
        if (
          this.editFormFields[key] === undefined &&
          this.dicKeys.some((x) => {
            return x.fileds && x.fileds.indexOf(key) != -1;
          })
        ) {
          editFormFields[key] = null;
        } else {
          editFormFields[key] = this.editFormFields[key];
        }
      }
    }
    //将数组转换成string
    //2020.11.01增加级联处理
    for (const key in editFormFields) {
      if (editFormFields[key] instanceof Array) {
        var iscascader = this.dicKeys.some((x) => {
          return (
            x.type == 'cascader' && x.fileds && x.fileds.indexOf(key) != -1
          );
        });
        if (iscascader && editFormFields[key].length) {
          editFormFields[key] =
            editFormFields[key][editFormFields[key].length - 1];
        } else {
          editFormFields[key] = editFormFields[key].join(',');
        }
      }
    }
    let formData = {
      mainData: editFormFields,
      detailData: null,
      delKeys: null,
      type: type,
      detailDataList: []
    };

    //获取明细数据(前台数据明细未做校验，待完.后台已经校验)
    if (this.hasDetail) {
      if (this.details != null && this.details.length > 1) {
        var length = this.details.length
        for (var i = 0; i < length; i++) {
          var tableIndex = this.details[i].tableIndex
          var detail = this.$refs['detail' + tableIndex][0]
          if (this.details[i].notSave) {
            continue;
          }
          var detailData = detail.rowData;
          let _fields = this.details[i].columns
            .filter((c) => {
              return (
                c.type == 'selectList' || (c.edit && c.edit.type == 'selectList') || c.type == 'treeSelect' || (c.edit && c.edit.type == 'treeSelect')
              );
            })
            .map((c) => {
              return c.field;
            });
          //2022.06.20增加保存时对明细表下拉框多选的判断
          if (_fields.length) {
            detailData = JSON.parse(JSON.stringify(detailData));
            detailData.forEach((row) => {
              for (let index = 0; index < _fields.length; index++) {
                const _field = _fields[index];
                if (Array.isArray(row[_field])) {
                  row[_field] = row[_field].join(',');

                  //如果是treeSelect并且不允许多选，则只取最后一条数据
                  var w = this.details[i].columns.filter(s => s.field == _field)[0]
                  if (((w.edit && w.edit.type == 'treeSelect') || w.type == 'treeSelect') && !w.edit.multiple) {
                    var splits = row[_field].split(',');
                    row[_field] = splits[splits.length - 1];
                  }
                }
              }
            });
          }
          formData.detailDataList.push({
            delKeys: this.details[i].detailOptions.delKeys,
            detailData: detailData,
            tableName: this.details[i].table
          })
        }
      } else {
        formData.detailData = this.$refs.detail.rowData;
        let _fields = this.detail.columns
          .filter((c) => {
            return (
              c.type == 'selectList' || (c.edit && c.edit.type == 'selectList') || c.type == 'treeSelect' || (c.edit && c.edit.type == 'treeSelect')
            );
          })
          .map((c) => {
            return c.field;
          });
        //2022.06.20增加保存时对明细表下拉框多选的判断
        if (_fields.length) {
          formData.detailData = JSON.parse(JSON.stringify(formData.detailData));
          formData.detailData.forEach((row) => {
            for (let index = 0; index < _fields.length; index++) {
              const _field = _fields[index];
              if (Array.isArray(row[_field])) {
                row[_field] = row[_field].join(',');

                //如果是treeSelect并且不允许多选，则只取最后一条数据
                var w = this.detail.columns.filter(s => s.field == _field)[0]
                if (((w.edit && w.edit.type == 'treeSelect') || w.type == 'treeSelect') && !w.edit.multiple) {
                  var splits = row[_field].split(',');
                  row[_field] = splits[splits.length - 1];
                }
              }
            }
          });
        }
      }
    }
    if (this.detailOptions.delKeys.length > 0) {
      formData.delKeys = this.detailOptions.delKeys;
    }
    //保存前拦截
    let _currentIsAdd = this.currentAction == this.const.ADD;
    if (_currentIsAdd) {
      //2020.12.06增加新建前异步处理方法
      //2021.08.16修复异步语法写错的问题
      if (!this.addBefore(formData) || !(await this.addBeforeAsync(formData)))
        return;
    } else {
      //2020.12.06增加修改前异步处理方法
      if (
        !this.updateBefore(formData) ||
        !(await this.updateBeforeAsync(formData))
      )
        return;
    }
    let url = this.getUrl(this.currentAction);
    this.http.post(url, formData, true).then((x) => {
      //保存后
      if (_currentIsAdd) {
        if (!this.addAfter(x)) return;
        //连续添加
        if (this.continueAdd && x.status) {
          this.$success(x.message);
          //新建
          this.currentAction = this.const.ADD;
          this.currentRow = {};
          this.resetAdd();
          this.refresh();
          return;
        }
      } else {
        if (!this.updateAfter(x)) return;
      }
      if (!x.status) return this.$error(x.message);
      var msg = x.message || '操作成功';
      if(msg.includes("保存成功") && type == 1){
        msg = msg.replace("保存成功", "提交成功");
      }
      this.$success(msg);
      //如果保存成功后需要关闭编辑框，直接返回不处理后面
      if (this.boxOptions.saveClose) {
        this.boxModel = false;
        //2020.12.27如果是编辑保存后不重置分页页数，刷新页面时还是显示当前页的数据
        this.$refs.table.load(null, _currentIsAdd);
        //this.refresh();
        return;
      }
      let resultRow;
      if (typeof x.data == 'string' && x.data != '') {
        resultRow = JSON.parse(x.data);
      } else {
        resultRow = x.data;
      }

      if (this.currentAction == this.const.ADD) {
        //  this.currentRow=x.data;
        this.editFormFields[this.table.key] = '';
        this.currentAction = this.const.EDIT;
        this.currentRow = resultRow.data;
      }
      this.resetEditForm(resultRow.data);
      //console.log(resultRow);
      if (this.hasDetail) {
        this.detailOptions.delKeys = [];
        if (resultRow.list) {
          this.$refs.detail.rowData.push(...resultRow.list);
        }
      }
      this.$refs.table.load(null, _currentIsAdd);
      // this.refresh();
    });
  },
  del(rows) {
    if (rows) {
      if (!(rows instanceof Array)) {
        rows = [rows];
      }
    } else {
      rows = this.$refs.table.getSelected();
    }
    //删除数据

    if (!rows || rows.length == 0) return this.$error('请选择要删除的行!');
    let delKeys = rows.map((x) => {
      return x[this.table.key];
    });
    if (!delKeys || delKeys.length == 0)
      return this.$error('没有获取要删除的行数据!');
    //删除前
    if (!this.delBefore(delKeys, rows)) {
      return;
    }
    let noDel = [];
    rows.forEach((row, index) => {
      if (row.AuditStatus != null && row.AuditStatus != 3 && row.AuditStatus != 4 && row.AuditStatus != -1) {
        noDel.push(row);
      }
    });
    if (noDel.length > 0) {
      return this.$error('数据提交流程后不可删除,请检查要删除的数据！');
    }
    let tigger = false;
    this.$confirm('确认要删除选择的数据吗?', '警告', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      center: true
    }).then(() => {
      if (tigger) return;
      tigger = true;
      let url = this.getUrl(this.const.DEL);
      this.http.post(url, delKeys, '正在删除数据....').then((x) => {
        if (!x.status) return this.$error(x.message);
        this.$success(x.message);
        //删除后
        if (!this.delAfter(x)) {
          return;
        }
        this.refresh();
      });
    });
  },
  async modelOpenBeforeAsync(row) {
    return true;
  },
  async initBox() {
    //2022.01.08增加新建时隐藏明细表导出功能
    this.detailOptions.buttons.forEach((x) => {
      if (x.value == 'export') {
        x.hidden = this.currentAction == 'Add' ? true : x.hidden;//修复修改时导出按钮显示问题
      }
    });
    //初始化新建、编辑的弹出框
    if (!(await this.modelOpenBeforeAsync(this.currentRow))) return false;
    this.modelOpenBefore(this.currentRow);
    if (!this.boxInit) {
      this.boxInit = true;
      this.boxModel = true;
      // this.detailUrl = this.url;
    }
    return true;
  },
  setEditForm(row) {
    // if (this.remoteColumns.length == 0 || !rows || rows.length == 0) return;
    var baseButtons = this.getBaseButtons(row);
    if (!row.hasOwnProperty('AuditStatus')) {
      //alert('无审批')
      //this.boxButtons = [];
      //this.boxButtons.push(baseButtons.filter(x => x.value == 'save' && x.type == 'primary')[0])
      //默认加上打印按钮
      var printButton = baseButtons.filter(x => x.value == 'print')[0]
      this.boxButtons.push(printButton)
    }
    else if (row.AuditStatus == null || row.AuditStatus == 4) {
      var auditOptions = this.columns.filter(x => x.field == 'AuditStatus' && !x.hidden);
      this.boxButtons = [];
      //待审批
      //this.boxButtons.push(baseButtons.filter(x => x.value == 'save' && x.type == 'primary')[0]);
      //this.boxButtons.push(baseButtons.filter(x => x.value == 'save' && x.type == 'warning')[0]);
      this.boxButtons.push(
        ...[
          {
            name: '保 存',
            icon: 'el-icon-check',
            type: 'primary',
            disabled: false,
            value: 'save',
            onClick() {
              this.save(0);
            }
          },
          {
            name: '提 交',
            icon: 'el-icon-s-promotion',
            type: 'warning',
            disabled: false,
            hidden: (auditOptions != null && auditOptions.length > 0) ? false : true,//如果查询页审批字段不显示，不显示提交按钮
            value: 'submit',
            onClick() {
              this.save(1);
            }
          }
        ]
      )
      //默认加上打印按钮
      var printButton = baseButtons.filter(x => x.value == 'print')[0]
      this.boxButtons.push(printButton)
    }
    else {
      this.boxButtons = [];
      //默认加上打印按钮
      var printButton = baseButtons.filter(x => x.value == 'print')[0]
      this.boxButtons.push(printButton)
      //alert('审批中或者审批完成')
      this.editFormOptions.forEach((row) => {
        row.forEach((x) => {
          x.disabled = true;
        });
      });
      if (this.editFormFieldsAfterDetail) {
        this.editFormOptionsAfterDetail.forEach((row) => {
          row.forEach((x) => {
            x.disabled = true;
          });
        });
      }
      //没有新增编辑权限的，弹出框都设置为只读
      this.detail.columns.forEach((column) => {
        if (column.hasOwnProperty('edit')) {
          column.readonly = true;
          // row['edit'] = false;
        }
      });
      this.details.forEach(item => {
        item.columns.forEach((column) => {
          if (column.hasOwnProperty('edit')) {
            column.readonly = true;
          }
        });
      })
      // this.boxButtons.push(
      //   ...[
      //     {
      //       name: " 审批记录",
      //       icon: 'el-icon-share',
      //       class: '',
      //       value: 'Audit',
      //       type: 'primary',
      //       onClick: function () {
      //         this.audit_record();
      //       }
      //     },
      //   ]
      // )
      //弹出框扩展按钮
      //this.extendBtn(boxButtons, this.extend.buttons.box);
      //弹出弹框按钮(2020.04.21),没有编辑或新建权限时，也可以通过buttons属性添加自定义弹出框按钮
      //this.boxButtons.push(...boxButtons);
      //this.detailOptions.buttons.push(detailGridButtons);
      //this.detailOptions.buttons.forEach((button) => {
      //if (!button.hasOwnProperty('hidden')) {
      // button.hidden = false;
      //}
      //});
      //弹出框扩展明细表按钮
      //this.extendBtn(this.detailOptions.buttons, this.extend.buttons.detail);

      //return boxButtons;
    }
    let remoteColumns = this.$refs.table.remoteColumns;
    remoteColumns.forEach((column) => {
      this.editFormOptions.forEach((option) => {
        option.forEach((x) => {
          if (x.field == column.field) {
            x.data.data = Object.assign([], x.data, column.bind.data);
          }
        });
      });
      if (this.editFormFieldsAfterDetail) {
        this.editFormOptionsAfterDetail.forEach((option) => {
          option.forEach((x) => {
            if (x.field == column.field) {
              x.data.data = Object.assign([], x.data, column.bind.data);
            }
          });
        });
      }
    });
    this.editFormFields;
    //重置编辑表单数据
    this.editFormFields[this.table.key] = row[this.table.key];

    this.resetEditForm(row);
    this.currentAction = this.const.EDIT;
    this.boxModel = true;
  },
  async linkData(row, column) {
    this.boxOptions.title = this.table.cnName + '(编辑)';
    //点击table单元格快捷链接显示编辑数据
    this.currentAction = this.const.EDIT;
    this.currentRow = row;
    if (!(await this.initBox())) return;
    this.resetDetailTable(row);
    this.setEditForm(row);
    this.setContinueAdd(false);
    //设置远程查询表单的默认key/value
    this.getRemoteFormDefaultKeyValue();
    //点击编辑按钮弹出框后，可以在此处写逻辑，如，从后台获取数据
    this.modelOpenProcess(row);
    //加载编辑页左边审批流程
    this.loadEditFormAudit([row])

    this.customData.optType = "AUDIT";
    var path = this.$route.path.split('/')[this.$route.path.split('/').length - 1]
    var hasEdit = this.filterPermission(path, 'Update');
    if (hasEdit && this.base.checkHasEdit(row)) {
      this.customData.optType = "EDIT";
    }
    //修复问题：先点击审批后的数据，再新增或修改数据，子表无法操作
    this.resetDetailTableColumns()
  },
  setContinueAdd(isAdd) {
    if (!this.continueAdd) return;
    var _button = this.boxButtons.find((x) => {
      return x.value == 'save';
    });
    if (_button) {
      _button.name = isAdd ? this.continueAddName : '保 存';
    }
  },
  resetAdd() {
    if (this.hasDetail) {
      this.$refs.detail &&
        //  this.$refs.detail.rowData &&
        this.$refs.detail.reset();
      //重置多子表tabs，默认显示第一个，同时重置表单
      if (this.details != null && this.details.length > 1) {
        this.$refs.detailTabs.currentName = '0';
        this.details.forEach((item, index) => {
          item.detailOptions.delKeys = []
          var detail = this.$refs["detail" + item.tableIndex][0]
          detail.reset()
        })
      }
      //修复问题：先点击审批后的数据，再新增或修改数据，子表无法操作
      this.resetDetailTableColumns()
    }
    let obj = {};
    //如果有switch标签，默认都设置为否
    this.editFormOptions.forEach((x) => {
      x.forEach((item) => {
        item.disabled = item.disabled;
        if (item.type == 'switch') {
          obj[item.field] = 0;
        }
      });
    });
    if (this.editFormFieldsAfterDetail) {
      this.editFormOptionsAfterDetail.forEach((x) => {
        x.forEach((item) => {
          item.disabled = item.disabled;
          if (item.type == 'switch') {
            obj[item.field] = 0;
          }
        });
      });
    }

    //没有新增编辑权限的，弹出框都设置为只读
    this.detail.columns.forEach((column) => {
      if (!column.hasOwnProperty('edit')) {
        column.readonly = true;
        // row['edit'] = false;
      }
    });
    this.details.forEach(item => {
      item.columns.forEach((column) => {
        if (!column.hasOwnProperty('edit')) {
          column.readonly = true;
        }
      })
    })

    this.boxButtons = [];
    //待审批
    this.boxButtons.push(
      ...[
        {
          name: '保 存',
          icon: 'el-icon-check',
          type: 'primary',
          disabled: false,
          value: 'save',
          onClick() {
            this.save(0);
          }
        }
      ]
    )
    var auditOptions = this.columns.filter(x => x.field == 'AuditStatus' && !x.hidden);
    //if (auditOptions != null && auditOptions.length > 0) {
    this.boxButtons.push(
      ...[
        {
          name: '提 交',
          icon: 'el-icon-s-promotion',
          type: 'warning',
          disabled: false,
          value: 'submit',
          hidden: auditOptions != null && auditOptions.length > 0 ? false : true,
          onClick() {
            this.save(1);
          }
        }
      ]
    )
    //}
    this.resetEditForm(obj);
  },
  async add() {
    this.boxOptions.title = this.table.cnName + '(新建)';
    //新建
    this.currentAction = this.const.ADD;
    this.currentRow = {};
    if (!(await this.initBox())) return;

    this.resetAdd();
    this.setContinueAdd(true);
    //  this.resetEditForm();
    this.boxModel = true;
    //点击新建按钮弹出框后，可以在此处写逻辑，如，从后台获取数据
    this.modelOpenProcess();
    // this.modelOpenAfter();

    //加载编辑页左边审批流程
    this.loadEditFormAudit(null);
    this.customData.optType = "";
  },
  async edit(rows) {
    this.boxOptions.title = '编辑';
    //编辑
    this.currentAction = this.const.EDIT;
    if (rows) {
      if (!(rows instanceof Array)) {
        rows = [rows];
      }
    } else {
      rows = this.$refs.table.getSelected();
    }
    if (rows.length == 0) {
      return this.$error('请选择要编辑的行!');
    }
    if (rows.length != 1) {
      return this.$error('只能选择一行数据进行编辑!');
    }
    //记录当前编辑的行
    this.currentRow = rows[0];
    //初始化弹出框
    if (!(await this.initBox())) return;
    this.setContinueAdd(false);
    //重置表单
    this.resetDetailTable();

    //设置当前的数据到表单上
    this.setEditForm(rows[0]);
    //设置远程查询表单的默认key/value
    this.getRemoteFormDefaultKeyValue();
    //点击编辑按钮弹出框后，可以在此处写逻辑，如，从后台获取数据
    this.modelOpenProcess(rows[0]);
    // this.modelOpenAfter(rows[0]);

    //加载编辑页左边审批流程
    this.loadEditFormAudit(rows);

    //修复问题：先点击审批后的数据，再新增或修改数据，子表无法操作
    this.resetDetailTableColumns()
  },
  getRemoteFormDefaultKeyValue() {
    //设置表单远程数据源的默认key.value
    if (this.currentAction != this.const.EDIT || this.remoteKeys.length == 0)
      return;
    this.editFormOptions.forEach((x, xIndex) => {
      x.forEach((item, yIndex) => {
        if (item.remote) {
          let column = this.columns.find((x) => {
            return x.bind && x.bind.key == item.dataKey;
          });
          if (!column) return;
          let key = this.currentRow[item.field];
          let obj = column.bind.data.find((x) => {
            return x.key == key;
          });
          // obj ? obj.value : key如果没有查到数据源，直接使用原数据
          item.data = [{ key: key, value: obj ? obj.value : key }];
          this.editFormOptions[xIndex].splice(yIndex, 1, item);
          // this.$set(item, 'data', [{ key: key + '', value: obj.value }])
          //  item.data = [{ key: key + '', value: obj.value }];
        }
      });
    });
    if (this.editFormFieldsAfterDetail) {
      this.editFormOptionsAfterDetail.forEach((x, xIndex) => {
        x.forEach((item, yIndex) => {
          if (item.remote) {
            let column = this.columns.find((x) => {
              return x.bind && x.bind.key == item.dataKey;
            });
            if (!column) return;
            let key = this.currentRow[item.field];
            let obj = column.bind.data.find((x) => {
              return x.key == key;
            });
            // obj ? obj.value : key如果没有查到数据源，直接使用原数据
            item.data = [{ key: key, value: obj ? obj.value : key }];
            this.editFormOptionsAfterDetail[xIndex].splice(yIndex, 1, item);
            // this.$set(item, 'data', [{ key: key + '', value: obj.value }])
            //  item.data = [{ key: key + '', value: obj.value }];
          }
        });
      });
    }
  },
  modelOpenProcess(row) {
    this.$nextTick(() => {
      let isEDIT = this.currentAction == this.const.EDIT;
      // debugger
      if (this.editInitFormOptions != null && this.editInitFormOptions.length > 0 && (this.currentAction == "Add" || (isEDIT && this.customData.optType == "EDIT"))) {
        var options = this.editInitFormOptions;
        //重置表单状态
        this.editFormOptions.forEach(items => {
          items.forEach(item => {
            var w = options.filter(x => x.field == item.field);
            if (w != null && w.length > 0) {
              item.disabled = w[0].disabled;
              item.hidden = w[0].hidden;
            }
          })
        })
      }
      this.modelOpenAfter(row);
    });
    return;
    // if (!this.$refs.form) {
    //     let timeOut = setTimeout(x => {
    //         this.modelOpenAfter(row);
    //     }, 500)
    //     return;
    // }
    // this.modelOpenAfter(row);
  },
  import() {
    //导入(上传excel),弹出导入组件UploadExcel.vue
    this.upload.excel = true;
    this.$refs.upload_excel && this.$refs.upload_excel.reset();
  },
  download(url, fileName) {
    //下载导出的文件
    let xmlResquest = new XMLHttpRequest();
    xmlResquest.open('GET', url, true);
    xmlResquest.setRequestHeader('Content-type', 'application/json');
    xmlResquest.setRequestHeader(
      'Authorization',
      this.$store.getters.getToken()
    );
    let elink = this.$refs.export;
    xmlResquest.responseType = 'blob';
    xmlResquest.onload = function (oEvent) {
      if (xmlResquest.status != 200) {
        this.$error('下载文件出错了..');
        return;
      }
      let content = xmlResquest.response;
      //  let elink = this.$refs.export;//document.createElement("a");
      elink.download = fileName; //+".xlsx";
      // elink.style.display = "none";
      let blob = new Blob([content]);
      elink.href = URL.createObjectURL(blob);
      //  document.body.appendChild(elink);
      elink.click();
      //  document.body.removeChild(elink);
    };
    xmlResquest.send();
  },
  getFileName(isDetail) {
    //2021.01.08增加导出excel时自定义文件名
    if (isDetail) {
      return this.detail.cnName + '.xlsx';
    }
    return this.table.cnName + '.xlsx';
  },
  export(isDetail) {
    //导出
    let url, query, param;
    if (isDetail) {
      //明细表导出时如果是新建状态，禁止导出
      if (this.currentAction == 'Add') {
        return;
      }
      url = `api/${this.detail.table}/${this.const.EXPORT}`;
      param = {
        wheres: [
          { name: this.table.key, value: this.editFormFields[this.table.key] }
        ]
      };
    } else {
      //主表导出
      url = this.getUrl(this.const.EXPORT);
      query = this.getSearchParameters();
      param = { order: this.pagination.order, wheres: query.wheres || [] };
    }
    //2020.06.25增加导出前处理
    if (!isDetail && !this.exportBefore(param)) {
      return;
    }

    if (param.wheres && typeof param.wheres == 'object') {
      param.wheres = JSON.stringify(param.wheres);
    }
    let $http = this.http;
    //2022.09.26增加自定义导出文件名
    let fileName = this.downloadFileName || this.getFileName(isDetail);
    //2021.01.08优化导出功能
    $http
      .post(url, param, '正在导出数据....', { responseType: 'blob' })
      .then((content) => {
        const blob = new Blob([content]);
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    //.then(result => {
    // if (!result.status) {
    //   return this.$error(result.message);
    // }
    // let path = this.getUrl(this.const.DOWNLOAD);
    // path = path[0] == "/" ? path.substring(1) : path;
    // this.download(
    //   $http.ipAddress + path + "?path=" + result.data,
    //   this.table.cnName + ".xlsx" // filePath
    // );
    ///  window.open($http.ipAddress + path + "?fileName=" + filePath, "_self");
    // });
  },
  exportDetail(detail) {
    //导出
    let url, query, param;
    if (this.currentAction == 'Add') {
      return;
    }
    url = `api/${detail.table}/${this.const.EXPORT}`;
    param = {
      wheres: [
        { name: this.table.key, value: this.editFormFields[this.table.key] }
      ]
    };

    if (param.wheres && typeof param.wheres == 'object') {
      param.wheres = JSON.stringify(param.wheres);
    }
    let $http = this.http;
    //2022.09.26增加自定义导出文件名
    let fileName = this.downloadFileName || this.getFileName(true);
    //2021.01.08优化导出功能
    $http
      .post(url, param, '正在导出数据....', { responseType: 'blob' })
      .then((content) => {
        const blob = new Blob([content]);
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
  },
  getSelectRows() {
    //获取选中的行
    return this.$refs.table.getSelected();
  },
  getDetailSelectRows() {
    //或获取明细选中的行
    if (!this.$refs.detail) {
      return [];
    }
    return this.$refs.detail.getSelected();
  },
  audit() {
    //审核弹出框
    let rows = this.$refs.table.getSelected();
    if (rows.length == 0) return this.$error('请选择要审核的行!');
    let auditStatus = Object.keys(rows[0]).find(x => { return x.toLowerCase() === 'auditstatus' });
    if (!auditStatus) {
      return this.$message.error(`表必须包括审核字段【AuditStatus】,并且是int类型`)
    }
    // let checkStatus = rows.every((x) => {
    //   return this.$global.audit.status.some(c => { return c === x[auditStatus] || !x[auditStatus] })
    // });
    // if (!checkStatus) return this.$error('只能选择待审批或审核中的数据!');
    this.$refs.audit.open(rows);
  },
  audit_edit(row) {
    let rows = [];
    rows.push(row == undefined ? this.currentRow : row);
    //审核弹出框
    //let rows = this.$refs.table.getSelected();
    //if (rows.length == 0) return this.$error('请选择要审核的行!');
    let auditStatus = Object.keys(rows[0]).find(x => { return x.toLowerCase() === 'auditstatus' });
    if (!auditStatus) {
      return this.$message.error(`表必须包括审核字段【AuditStatus】,并且是int类型`)
    }
    // let checkStatus = rows.every((x) => {
    //   return this.$global.audit.status.some(c => { return c === x[auditStatus] || !x[auditStatus] })
    // });
    // if (!checkStatus) return this.$error('只能选择待审批或审核中的数据!');
    this.$refs.audit.open(rows);
  },
  audit_record(row) {
    let rows = [];
    rows.push(row == undefined ? this.currentRow : row);
    //审核弹出框
    let auditStatus = Object.keys(rows[0]).find(x => { return x.toLowerCase() === 'auditstatus' });
    if (!auditStatus) {
      return this.$message.error(`表必须包括审核字段【AuditStatus】,并且是int类型`)
    }
    // let checkStatus = rows.every((x) => {
    //   return this.$global.audit.status.some(c => { return c === x[auditStatus] || !x[auditStatus] })
    // });
    // if (!checkStatus) return this.$error('只能选择待审批或审核中的数据!');
    this.$refs.audit.open(rows);
    this.$nextTick(() => {
      this.$refs.audit.activeName = 'log'
    })
  },
  async saveAudit(params, rows, callback, isEditTanfer, currentFlowStep) {

    //保存审核
    let keys = rows.map(x => { return x[this.table.key] });
    // if (!this.auditBefore(keys, rows,params,currentFlowStep)) {
    //   return;
    // }
    let checkAuditBeforeResult = true;
    checkAuditBeforeResult = await new Promise((resolve) => {
      resolve(this.auditBefore(keys, rows, params, currentFlowStep))
    })
    if (!checkAuditBeforeResult) {
      return;
    }

    let url = `${this.getUrl(this.const.AUDIT)}?auditReason=${params.reason}&auditStatus=${params.value}`
    this.http.post(url, keys, '审核中....').then((x) => {
      if (!this.auditAfter(x, keys, params, currentFlowStep)) {
        return;
      }
      if (!x.status) return this.$error(x.message);

      callback && callback(x);
      this.$success(x.message);
      if (this.isBatchAudit) {
        this.$refs.AuditStep.open(rows)
        return;
      }
      if (isEditTanfer) {
        //编辑表单页审批后，关闭弹窗
        this.boxModel = false;
      }
      this.refresh();
    });
  },
  saveReply(replyParam, rows, callback) {
    let keys = rows.map(x => { return x[this.table.key] });
    let url = `${this.getUrl('replyDetail')}?stepid=${replyParam.stepId}&userId=${replyParam.userId}&empId=${replyParam.empId}&remarks=${replyParam.remarks}`
    this.http.post(url, keys, '回复中....').then((x) => {
      if (!x.status) return this.$error(x.message);

      callback && callback(x);
      this.$success(x.message);
      this.refresh();
    });
  },
  viewModelCancel() {
    //查看表结构
    this.viewModel = false;
  },
  initFormOptions(formOptions, keys, formFields, isEdit) {
    //初始化查询、编辑对象的下拉框数据源、图片上传链接地址
    //let defaultOption = { key: "", value: "请选择" };
    //有上传的字段
    //2020.05.03新增
    //编辑数据源的类型
    formOptions.forEach((item) => {
      item.forEach((d) => {
        if (d.type == 'number') {
          //2022.08.22优化表单类型为number时的默认值
          if (formFields[d.field] === '') {
            formFields[d.field] = undefined;
          }
          this.numberFields.push(d.field);
        }
        if (
          d.type == 'img' ||
          d.type == 'excel' ||
          d.type == 'file' ||
          d.columnType == 'img'
        ) {
          d.url = this.http.ipAddress + 'api' + this.table.url + 'Upload';
          this.uploadfiled.push(d.field);
        }
        if (!d.dataKey) return true;
        //2022.02.20强制开启联级可以选择某个节点
        if (d.type == 'cascader' && !d.hasOwnProperty('changeOnSelect')) {
          //强制开启联级可以选择某个节点
          d.changeOnSelect = true;
        }
        //开启远程搜索
        if (d.remote) {
          this.remoteKeys.push(d.dataKey);
          d.data = []; //{ dicNo: d.dataKey, data: [] };
          return true;
        }
        //2020.05.03增加编辑表单对checkbox的支持
        if (d.type == 'checkbox' && !(formFields[d.field] instanceof Array)) {
          formFields[d.field] = [];
        }
        if (keys.indexOf(d.dataKey) == -1) {
          //2020.05.03增加记录编辑字段的数据源类型

          keys.push(d.dataKey);
          //2020.05.03修复查询表单与编辑表单type类型变成强一致性的问题
          //this.dicKeys.push({ dicNo: d.dataKey, data: [], type: d.type });
          //  2020.11.01增加iview组件Cascader数据源存储
          let _dic = {
            dicNo: d.dataKey,
            data: [],
            fileds: [d.field],
            orginData: []
          };
          if (d.type == 'cascader') {
            _dic.type = 'cascader';
          }
          if (isEdit) {
            _dic['e_type'] = d.type;
          }
          this.dicKeys.push(_dic);
        } else if (d.type == 'cascader') {
          this.dicKeys.forEach((x) => {
            if (x.dicNo == d.dataKey) {
              x.type = 'cascader';
              x.fileds.push(d.field);
            }
          });
        }
        if (d.type != 'cascader') {
          //2020.01.30移除内部表单formOptions数据源配置格式data.data，所有参数改为与组件api格式相同
          Object.assign(
            d,
            this.dicKeys.filter((f) => {
              return f.dicNo == d.dataKey;
            })[0],
            { type: d.type }
          );
        }
      });
    });
  },
  //初始table与明细表的数据源指向dicKeys对象，再去后台加载数据源
  initColumns(scoure, dicKeys, keys) {
    if (!scoure || !(scoure instanceof Array)) return;
    scoure.forEach((item) => {
      if (!item.bind || (item.bind.data && item.bind.data.length > 0))
        return true;
      let key = item.bind.key || item.bind.dicNo;
      if (this.remoteKeys.indexOf(key) != -1) {
        item.bind.remote = true;
        return true;
      }
      if (this.hasKeyField.indexOf(item.field) == -1) {
        this.hasKeyField.push(item.field);
      }
      var dic = dicKeys.filter((x) => {
        return x.dicNo == key;
      });
      if (!dic || dic.length == 0) {
        var k = { dicNo: key, data: [] };
        if (item.bind.type == 'treeSelect' && item.edit.type == 'treeSelect') {
          k.detailType = 'treeSelect'
        }
        dicKeys.push(k);
        dic = [dicKeys[dicKeys.length - 1]];
        keys.push(key);
      }
      //2020.11.01增加级联处理
      if (dic[0].type == 'cascader' || dic[0].type == 'treeSelect') {
        item.bind = { data: dic[0].orginData, type: 'select', key: key };
      } else {
        item.bind = dic[0];
      }
      //2020.05.03优化table数据源checkbox与select类型从编辑列中选取
      item.bind.type = item.bind.e_type || 'string';
    });
  },
  bindOptions(dic) {
    //绑定下拉框的数据源
    //绑定后台的字典数据
    dic.forEach((d) => {
      if (d.data.length >= (this.select2Count || 500)) {
        if (
          !this.dicKeys.some((x) => {
            return (
              x.dicNo == d.dicNo &&
              (x.type == 'cascader' || x.type == 'treeSelect')
            );
          })
        ) {
          d.data.forEach((item) => {
            item.label = item.value;
            item.value = item.key;
          });
        }
      }
      this.dicKeys.forEach((x) => {
        if (x.dicNo != d.dicNo) return true;
        //2020.10.26增加级联数据源绑定处理
        if (x.type == 'cascader' || x.type == 'treeSelect' || x.detailType == 'treeSelect') {
          // x.data=d.data;
          //生成tree结构
          let _data = JSON.parse(JSON.stringify(d.data));
          //2022.04.04增加级联字典数据源刷新后table没有变化的问题
          this.columns.forEach((column) => {
            if (column.bind && column.bind.key == d.dicNo) {
              column.bind.data = d.data;
            }
          });
          this.detailOptions.columns.forEach((column) => {
            if (column.bind && column.bind.key == d.dicNo) {
              column.bind.data = d.data;
            }
          });
          if (this.details != null && this.details.length > 0) {
            this.details.forEach(item => {
              item.columns.forEach((column) => {
                if (column.bind && column.bind.key == d.dicNo) {
                  column.bind.data = d.data;
                }
              });
            })
          }
          let arr = this.base.convertTree(_data, (node, data, isRoot) => {
            if (!node.inited) {
              node.inited = true;
              node.label = node.value;
              node.value = node.key;
            }
          });
          x.data.push(...arr);
          if (x.orginData == null) {
            x.orginData = [];
          }
          x.orginData.push(...d.data);
          //2021.10.17修复查询级联不能绑定数据源的问题
          this.searchFormOptions.forEach((searhcOption) => {
            searhcOption.forEach((_option) => {
              if (_option.type == 'cascader' && _option.dataKey == x.dicNo) {
                _option.data = arr;
                _option.orginData = d.data;
              }
            });
          });
          //2021.10.17修复级联不能二级刷新的问题
          this.editFormOptions.forEach((editOption) => {
            editOption.forEach((_option) => {
              if (
                (_option.type == 'cascader' || _option.type == 'treeSelect') &&
                _option.dataKey == x.dicNo
              ) {
                _option.data = arr;
                _option.orginData = d.data;
              }
            });
          });
          if (this.editFormFieldsAfterDetail) {
            this.editFormOptionsAfterDetail.forEach((editOption) => {
              editOption.forEach((_option) => {
                if (
                  (_option.type == 'cascader' || _option.type == 'treeSelect') &&
                  _option.dataKey == x.dicNo
                ) {
                  _option.data = arr;
                  _option.orginData = d.data;
                }
              });
            });
          }
        } else if (d.data.length > 0 && !d.data[0].hasOwnProperty('key')) {
          let source = d.data,
            newSource = new Array(source.length);
          for (let index = 0; index < source.length; index++) {
            newSource[index] = {
              //默认从字典数据读出来的key都是string类型,但如果数据从sql中查询的可能为非string,否是async-validator需要重置设置格式
              key: source['key'] + '', //source[index][x.config.valueField] + "",
              value: source['value'] //source[index][x.config.textField]
            };
          }

          x.data.push(...newSource);
        } else {
          //2020.06.06，如果是selectList数据源使用的自定义sql并且key是数字，强制转换成字符串
          if (
            x.e_type == 'selectList' &&
            d.data.length > 0 &&
            typeof d.data[0].key == 'number'
          ) {
            d.data.forEach((c) => {
              c.key = c.key + '';
            });
          }
          x.data.push(...d.data);
        }
        if (
          this.singleSearch &&
          this.singleSearch.dataKey &&
          this.singleSearch.dataKey == x.dicNo
        ) {
          this.singleSearch.data.splice(0, 1, ...x.data);
        }
      });
    });
  },
  getUrl(action, ingorPrefix) {
    //是否忽略前缀/  获取操作的url
    return (!ingorPrefix ? '/' : '') + 'api' + this.table.url + action;
  },
  initDicKeys() {
    //初始化字典数据
    let keys = [];
    //2022.04.17优化重新加载数据源
    this.dicKeys.forEach((item) => {
      item.data.splice(0);
      item.orginData && item.orginData.splice(0);
    });
    //this.dicKeys.splice(0);
    //初始化编辑数据源,默认为一个空数组，如果要求必填设置type=number/decimal的最小值
    this.initFormOptions(this.editFormOptions, keys, this.editFormFields, true);
    if (this.editFormFieldsAfterDetail) {
      this.initFormOptions(this.editFormOptionsAfterDetail, keys, this.editFormFieldsAfterDetail, true);
    }
    //初始化查询数据源,默认为一个空数组
    this.initFormOptions(
      this.searchFormOptions,
      keys,
      this.searchFormFields,
      false
    );
    //查询日期设置为可选开始与结果日期
    this.searchFormOptions.forEach((item) => {
      item.forEach((x) => {
        if (x.type == 'date' || x.type == 'datetime') x.range = true;
      });
    });
    //初始化datatable表数据源,默认为一个空数组,dicKeys为界面所有的数据字典编号
    this.initColumns(this.columns, this.dicKeys, keys);
    //2021.05.23默认开启查询页面所有字段排序,如果不需要排序，在onInited遍历columns设置sort=false
    //2021.09.25移除强制排序功能
    // this.columns.forEach(x => {
    //   x.sort = x.render ? false : true;
    // })
    if (this.detailOptions && this.detailOptions.columns) {
      this.initColumns(this.detailOptions.columns, this.dicKeys, keys);
    }
    if (this.details != null && this.details.length > 0) {
      this.details.forEach(item => {
        this.initColumns(item.columns, this.dicKeys, keys);
      })
    }
    //初始化快速查询字段,默认使用代码生成器配置的第一个查询字段
    if (this.searchFormOptions.length > 0) {
      this.singleSearch = {
        dataKey: this.searchFormOptions[0][0].dataKey,
        dicNo: this.searchFormOptions[0][0].dicNo,
        field: this.searchFormOptions[0][0].field,
        title: this.searchFormOptions[0][0].title,
        type: this.searchFormOptions[0][0].type,
        data: []
      };
      // this.singleSearch = this.searchFormOptions[0][0];
    }
    if (keys.length == 0) return;
    let $this = this;
    this.http.post('/api/Sys_Dictionary/GetVueDictionary', keys).then((dic) => {
      $this.bindOptions(dic);
      //2022.04.04增加字典加载完成方法
      $this.dicInited && $this.dicInited(dic);
    });
  },
  setFiexdColumn(columns, containerWidth) {
    //计算整个table的宽度，根据宽度决定是否启用第一行显示的列为固定列
    //2021.09.21移除强制固定第一列
    // let columnsWidth = 0;
    // columns.forEach(x => {
    //   if (!x.hidden && x.width) {
    //     columnsWidth += x.width;
    //   }
    // });
    //启用第一列为固定列
    // if (columnsWidth > containerWidth) {
    //   let firstColumn = columns.find(x => !x.hidden);
    //   if (firstColumn) {
    //     firstColumn.fixed = true;
    //   }
    // }
  },
  initBoxHeightWidth() {
    //初始化弹出框的高度与宽度
    let clientHeight = document.documentElement.clientHeight;
    //弹出框高度至少250px
    clientHeight = clientHeight < 250 ? 250 : clientHeight;
    let clientWidth = document.documentElement.clientWidth;
    if (
      this.editFormOptions.some((x) => {
        return x.some((item) => {
          return item.type == 'editor';
        });
      }) || (this.editFormFieldsAfterDetail && this.editFormOptionsAfterDetail.some((x) => {
        return x.some((item) => {
          return item.type == 'editor';
        });
      }))
    ) {
      this.editor.uploadImgUrl = this.getUrl('upload');
      this.boxOptions.height = clientHeight * 0.8;
      this.boxOptions.width = clientWidth * 0.8;
    } else {
      if (this.boxOptions.height) {
        //如果高度与宽度超过了获取到的可见高宽度，则设为默认的90%高宽
        if (this.boxOptions.height > clientHeight * 0.8) {
          this.boxOptions.height = clientHeight * 0.8;
        }
      }
      if (this.boxOptions.width) {
        //如果高度与宽度超过了获取到的可见高宽度，则设为默认的90%高宽
        if (this.boxOptions.width > clientWidth * 0.8) {
          this.boxOptions.width = clientWidth * 0.8;
        }
      }
    }
    //计算整个table的宽度，根据宽度决定是否启用第一行显示的列为固定列
    let maxTableWidth = clientWidth - 270;
    this.setFiexdColumn(this.columns, maxTableWidth);

    this.height = this.tableHeight || clientHeight - 206;
    this.url = this.getUrl(this.const.PAGE);
    //计算弹出框的高与宽度
    //如果有明细表，高度与宽带设置为0.9/0.82
    if (this.detail.columns && this.detail.columns.length > 0) {
      this.hasDetail = true;
      clientWidth = clientWidth * 0.8;
      clientHeight = clientHeight * 0.85;
      if (!this.detailOptions.height) {
        this.detailOptions.height =
          clientHeight - this.editFormOptions.length * 36 - 234;
        this.detailOptions.height =
          this.detailOptions.height < 240 ? 240 : this.detailOptions.height;
      }

      this.detailOptions.columns = this.detail.columns;
      this.detailOptions.pagination.sortName = this.detail.sortName;
      this.detailOptions.cnName = this.detail.cnName;
      this.detailOptions.key = this.detail.key;
      this.detailOptions.url = this.getUrl('getDetailPage');
      this.detailOptions.mergeRow = this.detail.mergeRow || false;
      this.detailOptions.mergeRowFields = this.detail.mergeRowFields || [];
      this.detailOptions.dynamicComponent = this.detail.dynamicComponent || false
      //计算弹出框整个table的宽度，根据宽度决定是否启用第一行显示的列为固定列
      this.setFiexdColumn(this.detail.columns, clientWidth);
      if (this.details != null && this.details.length > 1) {
        var index = -1
        this.details.forEach(x => {
          index += 1;
          x.index = index
          var options = this.getDetailsOptions();
          if (!x.detailOptions) {
            x.detailOptions = options
          }
          else {
            Object.assign(options, x.detailOptions)
            x.detailOptions = options
          }
          if (!x.detailOptions.height) {
            x.detailOptions.height =
              clientHeight - this.editFormOptions.length * 36 - 234;
            x.detailOptions.height =
              x.detailOptions.height < 240 ? 240 : x.detailOptions.height;
          }
          x.detailOptions.columns = x.columns
          x.detailOptions.cnName = x.cnName
          x.detailOptions.key = x.key
          x.detailOptions.url = x.table != "" ? "/api/" + x.table + "/getPageData" : "";
          x.detailOptions.buttons = []
          x.detailOptions.buttons.push(...this.getDetailsButtons())
          x.detailOptions.mergeRow = x.mergeRow || false;
          x.detailOptions.mergeRowFields = x.mergeRowFields || [];
          x.detailOptions.dynamicComponent = x.dynamicComponent || false
        })
      }
    } else {
      let maxColumns = 1; //最大列数，根据列计算弹框的宽度
      this.editFormOptions.forEach((x) => {
        if (x.length > maxColumns) maxColumns = x.length;
      });
      let maxHeightRate = 0.7,
        maxWidthRate = 0.5;
      maxWidthRate = maxColumns / 10 + 0.3;
      maxHeightRate = (this.editFormOptions.length || 1) * 0.1 + 0.03;
      maxHeightRate = maxHeightRate > 0.9 ? 0.9 : maxHeightRate;
      clientWidth = clientWidth * maxWidthRate;
      clientHeight = clientHeight * maxHeightRate;
      // this.boxOptions.width = clientWidth * maxWidthRate;
      // this.boxOptions.height = clientHeight * maxHeightRate;
    }
    if (!this.boxOptions.height) {
      this.boxOptions.height = clientHeight + 10;
    }
    if (!this.boxOptions.width) {
      this.boxOptions.width = clientWidth + 30;
    }
  },
  rowOnChange(row) {
    this.rowChange(row);
  },
  rowChange(row) {
    //选中行checkbox行事件
  },
  rowOnClick({ row, column, event }) {
    this.rowClick({ row, column, event });
  },
  rowClick({ row, column, event }) {
    // 点击行事件(2020.11.07)
  },
  rowOnDbClick({ row, column, event }) {
    this.rowDbClick({ row, column, event });
  },
  rowDbClick({ row, column, event }) {
    // 双击击行事件(2021.05.23)
  },
  $error(message) {
    this.$message.error(message);
    // this.$message({
    //   type: 'error',
    //   content: message,
    //   duration: 5
    // });
  },
  $success(message) {
    this.$message.success(message);
  },
  setFiexdSearchForm(visiable) {
    //2020.09.011增加固定查询表单功能,visiable=true默认将查询表单展开
    this.fiexdSearchForm = true;
    let refreshBtn = this.buttons.find((x) => x.name == '刷 新');
    if (visiable) {
      this.searchBoxShow = true;
    }
    if (refreshBtn) {
      refreshBtn.name = '重 置';
      refreshBtn.onClick = function () {
        this.resetSearch();
      };
    }
  },
  tableBeginEdit(row, column, index) {
    //2021.03.19是否开启查询界面表格双击编辑结束方法,返回false不会结束编辑
    return this.beginEdit(row, column, index);
  },
  beginEdit(row, column, index) {
    //2021.03.19是否开启查询界面表格双击编辑结束方法,返回false不会结束编辑
    return true;
  },
  tableEndEditBefore(row, column, index) {
    return this.endEditBefore(row, column, index);
  },
  endEditBefore(row, column, index) {
    //2021.03.19是否开启查询界面表格双击编辑结束方法,返回false不会结束编辑
    return true;
  },
  filterPermission(tableName, permission) {
    //2021.03.19判断是否有某个表的按钮权限
    //:["Search","Add","Delete","Update","Import","Export","Upload","Audit"]
    const _result = (this.$store.state.permission || []).find((x) => {
      return x.url == '/' + tableName;
    });
    return _result && _result.permission.some((x) => x == permission);
  },
  destroyed() {
    //2021.04.11增加vue页面销毁方法,路由必须设置keepLive:false，设置方法见：前端开发文档-》[禁用页面缓存keepAlive]
  },
  loadTreeTableChildren(tree, treeNode, resolve) {
    this.loadTreeChildren.call(this, tree, treeNode, resolve);
  },
  loadTreeChildren(tree, treeNode, resolve) {
    //树形结构加载子节点(2021.05.02),在onInit中设置了rowKey主键字段后才会生效
    return resolve([]);
  },
  importDetailAfter(data) {
    //2022.01.08增加明细表导入后处理
  },
  importExcelAfter(data) {
    //2022.01.08增加明细表导入后方法判断

    if (!data.status) {
      return; // this.$message.error(data.message);
    }
    //明细表导入
    if (this.boxModel) {
      if (data.data) {
        data.data = JSON.parse(data.data);
      } else {
        data.data = [];
      }
      data.data.forEach((x) => {
        x[this.detail.key] = undefined;
        x[this.table.key] = undefined;
      });
      this.importDetailAfter(data); //增加明细表导入后处理
      this.$refs.detail.rowData.unshift(...data.data);
      this.upload.excel = false;
      return;
    }
    this.importAfter(data);
  },
  onGridModelClose(iconClick) {
    if (this.isBoxAudit) {
      this.initFormOptionType(false);
    }
    this.isBoxAudit = false;
    this.onModelClose(iconClick);
  },
  initAuditColumn() {

  },
  getWorkFlowSteps(row) {
    let table = this.table.url.replaceAll('/', '');
    let url = `api/Sys_WorkFlow/getSteps?tableName=${table}&id=${row[this.table.key]
      }`;
    this.http.get(url, {}, true).then((result) => {
      this.workFlowSteps.splice(0);
      //有可能没有配置审批流程
      if (!result.list || !result.list.length) {
        result.list = [];
        this.auditParam.showAction = true;
        this.auditParam.height = 240;
        this.auditParam.showViewButton = row.AuditStatus == 0;
      } else {
        this.auditParam.showAction = result.list.some((c) => {
          return c.isCurrentUser;
        });
        this.auditParam.height = 511;
        this.auditParam.showViewButton = true;
      }
      this.auditParam.reason = '';
      this.auditParam.status = -1;
      this.auditParam.value = -1;
      if (result.his) {
        result.his.forEach((item) => {
          item.auditStatus = this.getAuditStatus(item.auditStatus);
        });
      }

      this.auditParam.auditHis = result.his;
      this.workFlowSteps.push(...result.list);
      this.isBoxAudit = true;
      this.initFormOptionType(true);
      this.edit(row);
      this.boxOptions.title = '审核';
    });
  },
  initFormOptionType(isReadonly) {
    this.editFormOptions.forEach((options) => {
      options.forEach((option) => {
        if (isReadonly) {
          if (!option.readonly) {
            this.formFieldsType.push(option.field);
            option.readonly = true;
          }
        } else {
          if (this.formFieldsType.indexOf(option.field) != -1) {
            option.readonly = false;
          }
        }
      });
    });
    if (this.editFormFieldsAfterDetail) {
      this.editFormOptionsAfterDetail.forEach((options) => {
        options.forEach((option) => {
          if (isReadonly) {
            if (!option.readonly) {
              this.formFieldsType.push(option.field);
              option.readonly = true;
            }
          } else {
            if (this.formFieldsType.indexOf(option.field) != -1) {
              option.readonly = false;
            }
          }
        });
      });
    }
  },
  getAuditStatus(status) {
    let data = this.auditParam.data.find((x) => {
      return x.value == status;
    });
    if (!data) {
      return '-';
      //   return `审核值不正确:${status}`
    }
    return data.text;
  },
  initFlowQuery() {
    if (this.$route.query.viewflow) {
      this.$refs.table && this.search();
    }
  },
  getBaseButtons(row) {
    var buttons = [
      {
        name: '保 存',
        icon: 'el-icon-check',
        type: 'primary',
        disabled: false,
        value: 'save',
        onClick() {
          this.save(0);
        }
      },
      {
        name: '提 交',
        icon: 'el-icon-s-promotion',
        type: 'warning',
        disabled: false,
        value: 'submit',
        onClick() {
          this.save(1);
        }
      },
      // {
      //   name: " 审批记录",
      //   icon: 'el-icon-share',
      //   class: '',
      //   value: 'Audit',
      //   type: 'primary',
      //   onClick: function () {
      //     this.audit_record();
      //   }
      // },
      {
        name: '打 印',
        icon: 'el-icon-printer',
        disabled: false,
        hidden: true,
        value: 'print',
        onClick() {

        }
      },
    ]
    return buttons;
  },
  getIntArray(str, splitChar) {
    var dataStrArr = str.toString().split(splitChar);
    var dataIntArr = [];
    dataStrArr.forEach(function (data, index, arr) {
      dataIntArr.push(+data);
    });
    return dataIntArr;
  },
  getStringArray(str, splitChar) {
    return str.toString().split(splitChar);
  },
  fullscreen(full) { //弹出框全屏方法
    this.customData.fullscreen = full
    //2023-11.11 左新加：全屏时重新计算box内容宽度
    var clientWidth = document.documentElement.clientWidth;
    this.loadEditFormAudit([this.currentRow], full ? clientWidth : 0)
    //2023-11.11 左新加：全屏时重新计算box内容宽度
  },
  initTableOptButton(optConfig, initConfig = null) {
    var path = this.$route.path.split('/')[this.$route.path.split('/').length - 1]
    //wangpei20210118,多模块公用单表时，此时name并非表名，会报异常
    // 原代码：var tableName = this.table.name.split('/')[this.table.name.split('/').length-1]
    var tableName = this.table.url.replaceAll('/', '')
    var tableKeyColumn = this.table.key;

    var editConfig = null; var deleteConfig = null; var auditConfig = null; var recallConfig = null; var customConfig = null;
    if (optConfig != null) {
      optConfig.forEach(x => {
        if (x.type == 'Edit') {
          editConfig = x;
        }
        else if (x.type == 'Delete') {
          deleteConfig = x
        }
        else if (x.type == 'Audit') {
          auditConfig = x
        }
        else if (x.type == 'ReCall') {
          recallConfig = x
        }
        else if (x.type == 'Custom') {
          customConfig = x.buttons
        }
      })
    }

    var hasEdit = this.filterPermission(path, 'Update');
    if (!hasEdit) {
      //没有编辑权限，去除编辑页的保存按钮
      var noSaveButton = this.boxButtons.filter(x => x.value != "save");
      this.boxButtons = noSaveButton;
    }
    var showEdit = true
    if (editConfig != null && editConfig.hasOwnProperty('show') && !editConfig.show) {
      showEdit = false
    }

    var hasDelete = this.filterPermission(path, 'Delete');
    var showDelete = true
    if (deleteConfig != null && deleteConfig.hasOwnProperty('show') && !deleteConfig.show) {
      showDelete = false
    }

    var hasRecall = false;
    var auditOptions = this.columns.filter(x => x.field == "AuditStatus" && !x.hidden);
    if (auditOptions != null && auditOptions.length > 0) {
      hasRecall = true;
    }

    this.columns.push({
      title: (initConfig != null && initConfig.title) ? initConfig.title : '操作',
      hidden: false,
      align: "center",
      fixed: 'right',
      width: (initConfig != null && initConfig.width) ? initConfig.width : 150,
      render: (h, { row, column, index }) => {
        var editPermision = false
        if (hasEdit && this.base.checkHasEdit(row)) {
          editPermision = true;
        }
        var buttons = [];
        if (showEdit) {
          //showEdit第一步判断：判断菜单栏是否开启了编辑权限
          //currentHasEdit第二步判断：判断editConfig中动态返回是否显示，默认显示

          var currentHasEdit = true
          if (editConfig && editConfig.callShow) {
            currentHasEdit = editConfig.callShow(row)
          }
          if (currentHasEdit) {
            buttons.push(
              <a class={[editPermision ? 'el-icon-edit-outline' : 'el-icon-document']} style={[{ 'font-size': '13px' }, { 'cursor': 'pointer' }, { 'color': 'rgb(64, 158, 255)' }, { 'margin': '0 5px' }]}
                title={editPermision ? '编辑' : '查看详情'}
                onClick={($e) => {
                  $e.stopPropagation()
                  if (editConfig != null && editConfig.onClick != null) {
                    return editConfig.onClick(row, column, index)
                  }
                  this.customData.optType = "AUDIT";
                  if (hasEdit && this.base.checkHasEdit(row)) {
                    this.customData.optType = "EDIT";
                  }
                  this.edit(row);
                }}>
              </a>
            )
          }
        }

        if (hasDelete && showDelete) {
          //第一步判断：判断菜单栏是否开启了删除权限
          //currentHasDelete第二步判断：判断deleteConfig中动态返回是否显示，默认显示

          var currentHasDelete = true
          if (deleteConfig && deleteConfig.callShow) {
            currentHasDelete = deleteConfig.callShow(row)
          }
          if (currentHasDelete) {
            buttons.push(
              <a title='删除' v-show={hasDelete && showDelete} class={['el-icon-delete']} style={[{ 'font-size': '13px' }, { 'cursor': 'pointer' }, { 'color': 'red' }, { 'margin': '0 5px' }]}
                onClick={($e) => {
                  $e.stopPropagation()
                  this.del(row);
                }}>
              </a>
            )
          }
        }

        buttons.push(
          <el-button v-show={(recallConfig == null || (recallConfig != null && recallConfig.show)) && row.hasOwnProperty("AuditStatus") && hasRecall} type='danger' title='撤回' disabled={(row.AuditStatus == null || row.AuditStatus == 1) ? true : false}
            style={[{ 'width': '40px' }, { 'height': '25px' }, { 'color': 'white' }, { 'margin': '0 5px' }]}
            onClick={($e) => {
              $e.stopPropagation()
              this.http.post("/api/Sys_WorkFlow/checkReCallPermission?tableName=" + tableName, [row[tableKeyColumn]]).then((result) => {
                if (!result.status) {
                  this.$message({
                    dangerouslyUseHTMLString: true,
                    message: result.message,
                    type: 'error'
                  });
                  return;
                }
                this.$confirm('确认要撤回该流程吗？', '警告', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                  center: true
                }).then(() => {

                  this.http.post("/api/Sys_WorkFlow/reCallFlowStep?tableName=" + tableName, {
                    tableKey: row[tableKeyColumn],
                    tableKeyColumn: tableKeyColumn,
                    tableName: tableName
                  }).then((result) => {
                    if (!result.status) {
                      this.$message({
                        dangerouslyUseHTMLString: true,
                        message: result.message,
                        type: 'error'
                      });
                      return;
                    }

                    this.$Message.success('流程撤回成功');
                    this.refresh();
                  })
                });
              })
            }}
          >撤回
          </el-button>
        );
        if (customConfig != null) {
          customConfig.forEach(x => {
            x.show = true//默认显示
            if (x.callShow) {
              x.show = x.callShow(row)
            }
            buttons.push(
              <el-button type={x.type} icon={x.icon} title={x.title} v-show={x.show} style={[{ 'height': '25px' }, { 'color': 'white' }, { 'margin': '0 5px' }, { 'background-color': x.backgroundColor }]} onClick={($e, d) => {
                $e.stopPropagation()
                if (x != null && x.onClick != null) {
                  return x.onClick(row, column, index)
                }
              }}>{x.title}</el-button>
            )
          })
        }
        return (
          <div>
            {buttons}
          </div>
        );
      }
    })
    //编辑和删除，列表都有，所以默认显示


    // return (
    //   <div>
    //     <a class={[(editConfig != null && editConfig.show) ? 'el-icon-edit-outline' : 'el-icon-document']} style={[{ 'font-size': '13px' }, { 'cursor': 'pointer' }, { 'color': 'rgb(64, 158, 255)' }, { 'margin': '0 5px' }]}
    //       title={(editConfig != null && editConfig.show) ? '编辑' : '查看详情'}
    //       onClick={($e) => {
    //         $e.stopPropagation()
    //         var callback = editConfig == null ? {} : editConfig.callback
    //         return callback(true)
    //       }}>
    //     </a>
    //     <a title='删除' v-show={deleteConfig != null && deleteConfig.show} class={['el-icon-delete']} style={[{ 'font-size': '13px' }, { 'cursor': 'pointer' }, { 'color': 'red' }, { 'margin': '0 5px' }]}
    //       onClick={($e) => {
    //         $e.stopPropagation()
    //         var callback = deleteConfig == null ? {} : deleteConfig.callback
    //         return callback(true)
    //       }}>
    //     </a>
    //     <el-button v-show={auditConfig != null && auditConfig.show} type='warning' title='审批' disabled={auditConfig != null && auditConfig.disabled ? true : false}
    //       style={[{ 'width': '40px' }, { 'height': '25px' }, { 'color': 'white' }, { 'margin': '0 5px' }]}
    //       onClick={($e, d) => {
    //         $e.stopPropagation()
    //         var callback = auditConfig == null ? {} : auditConfig.callback
    //         return callback(true)
    //       }}
    //     >审批
    //     </el-button>
    //     <el-button v-show={recallConfig != null && recallConfig.show} type='danger' title='撤回' disabled={recallConfig != null && recallConfig.disabled ? true : false}
    //       style={[{ 'width': '40px' }, { 'height': '25px' }, { 'color': 'white' }, { 'margin': '0 5px' }]}
    //       onClick={($e) => {
    //         $e.stopPropagation()
    //         var callback = recallConfig == null ? {} : recallConfig.callback
    //         return callback(true)
    //       }}
    //     >撤回
    //     </el-button>
    //   </div>
    // );
  },
  loadEditFormAudit(rows, width) {
    if (rows != null && rows[0].hasOwnProperty('AuditStatus') && rows[0]['AuditStatus'] != null) {
      this.$refs.AuditStep.open(rows)
      this.$refs.panelEditLeft.className = 'box-com xpanel1';
      this.panelEditRight.width = 230
      if (this.base.checkEmpty(width)) {
        width = this.boxOptions.width;
      }
      this.panelEditLeft.width = width - this.panelEditRight.width - 60;
    }
    else {
      this.hiddenAudit(width);
    }
  },
  auditStepLoadAfter(row, currentFlowStep) {
    // 编辑页右边流程数据加载完后回调方法 2023.8.31 左：新加
    this.auditLoadAfter(row, currentFlowStep)
    if (!this.$refs.AuditStep.model) {
      //如果右边流程面板不显示，特殊处理
      this.hiddenAudit(this.customData.fullscreen ? document.documentElement.clientWidth : 0);
    }
  },
  async getWorkFlowStepsList(id, tableName) {
    this.customData.workFlowSteps = []
    this.customData.currentFlowStep = {};
    if (id == '' || id == null) {
      return;
    }
    await this.http.post("/api/Sys_WorkFlow/getSteps?tableName=" + tableName, [id]).then((result) => {
      if (result.status && result.list != null && result.list.length > 0) {
        this.customData.workFlowSteps = result.list;
        var filter = result.list.filter(x => x.stepId == result.step);
        if (filter != null && filter.length > 0) {
          this.customData.currentFlowStep = filter[0]
        }
      }
    })
  },
  hiddenAudit(width) {
    if (this.base.checkEmpty(width)) {
      width = this.boxOptions.width;
    }
    this.$refs.AuditStep.model = false;
    this.$refs.panelEditLeft.className = 'box-com';
    this.panelEditLeft.width = width - 40
    this.panelEditRight.width = 0
  },
  initSearchAuditField() {
    //默认加上审核状态查询
    var AuditStatusField = "AuditStatus";
    var filter = this.columns.filter(x => x.field == AuditStatusField && !x.hidden);
    if (!this.searchFormFields.hasOwnProperty(AuditStatusField) && filter != null && filter.length > 0 && (this.searchFormFields[AuditStatusField] == null || this.searchFormFields[AuditStatusField] == undefined)) {
      this.searchFormFields[AuditStatusField] = "";
      var option = {
        field: AuditStatusField,
        title: filter[0].title,
        type: "select",
        dataKey: 'audit',
        colSize: 3,
        data: filter[0].bind.data
      }
      //wangpei-2023-11-15-add-start
      //如果最后一行不够四个，则添加到此行
      if (this.searchFormOptions != null && this.searchFormOptions.length > 0 && this.searchFormOptions[this.searchFormOptions.length - 1].length < 4) {
        this.searchFormOptions[this.searchFormOptions.length - 1].push(
          option
        )
      } else {
        //否则添加到最后一行
        this.searchFormOptions.push([
          option
        ])
      }
      //wangpei-2023-11-15-add-end
    }

  },
  handlePrint() {
    var cnName = this.table.cnName;
    if (!this.base.checkEmpty(cnName)) {
      cnName = '-' + cnName
    }
    var _this = this;
    var html = this.computePrintHtml();
    // const { href } = this.$router.resolve({
    //   path: '/print'
    // });
    // window.open(href, '_blank');
    // return
    var userName = _this.$store.getters.getUserInfo().userName
    var header = "<div style='display:inline'>";
    header += "<img width='115' height='50' src='http://oa.nddrcy.com/img/logo2.1f76f00f.png'/>";
    header += "<div style='font-size:25px;text-align:center'>银典城市运营集团" + cnName + "</div>";

    var style = "@page {margin: 0 10mm;}";//不打印页眉页脚
    printjs({
      printable: html,
      type: 'raw-html',
      documentTitle: "银典城市运营集团",
      header: header,
      base64: true,
      scanStyle: false,
      css: "http://localhost:9991/css/print.css",
      //headerStyle: 'font-weight:400;text-align:center;',
      style: style, // 不打印页眉和页脚
      honorColor: true, // 是否打印彩色文本
      targetStyles: ['*'] // 允许打印所有样式属性
    })
    return;
    domToImage.toJpeg(this.$refs.panelEditLeft, {
      quality: 1,
      bgcolor: 'white',
    })
      .then(function (dataUrl) {
        var userName = _this.$store.getters.getUserInfo().userName
        var header = "<div style='display:inline'>";
        header += "<img wisth='115' height='50' src='http://oa.nddrcy.com/img/logo2.1f76f00f.png'/>";
        header += "<div style='font-size:25px;text-align:center'>银典城市运营集团" + cnName + "</div>";

        var style = "@page {margin: 0 10mm;} label{border:1px solid}";//不打印页眉页脚
        style += "@media print {"
        style += "label{border:1px solid}";
        style += "}";
        printjs({
          printable: dataUrl,
          type: 'image',
          documentTitle: "银典城市运营集团",
          header: header,
          base64: true,
          scanStyle: false,
          //css: "http://oa.nddrcy.com/css/print.css",
          headerStyle: 'font-weight:400;text-align:center;',
          style: style, // 不打印页眉和页脚
          honorColor: true, // 是否打印彩色文本
          targetStyles: ['*'] // 允许打印所有样式属性
        })
      });
  },
  computePrintHtml() {
    var tableCnName = this.table.cnName;
    var html = "";
    //自定义table-非代码生成
    ["modelHeader"].forEach(model => {
      if (this.$refs[model] != null && this.$refs[model].$refs != null) {
        var refs = this.$refs[model].$refs;
        for (let key in refs) {
          var table = refs[key];
          if (key == "custodyResponsibilityLetter") {
            //保管责任书-特殊处理
            var innerHTML = ""
            table.children.forEach(c => {
              if (this.base.checkEmpty(c.innerText)) {
                return;
              }
              if (c.children == null || c.children.length <= 0) {
                innerHTML += "<p>" + c.innerText + "</p>"
              }
              else {
                innerHTML = this.getAllChildrenText(refs, c, innerHTML)
              }
            })
            // var innerHTML = document.querySelector("#custodyResponsibilityLetter").innerHTML.replace("NaN","");
            // //清除class、style、width、height
            // innerHTML = innerHTML.replace(/(style="display(.*?)none")/ig,'class="displaynone"')
            // .replace(/(style="(.*?)")|(class="(.*?)")|(width="(.*?)")|(height="(.*?)")/ig,'')
            //var innerHTML = document.querySelector("#custodyResponsibilityLetter").innerHTML.replace("NaN","").replace(/\n/ig,"<br>");
            //清除class、style、width、height
            //innerHTML = innerHTML.replace(/(style="display(.*?)none")/ig,'class="displaynone"')
            //.replace(/(style="(.*?)")|(width="(.*?)")|(height="(.*?)")/ig,'')
            html += innerHTML
            continue;
          }
        }
      }
    })

    //获取最大列数
    var columnNum = 0;
    this.editFormOptions.forEach(items => {
      var items_n = items.filter(item => !item.hidden && !this.base.checkEmpty(item.title))
      if (items_n != null && items_n.length > columnNum) {
        columnNum = items_n.length
      }
    })
    html += "<table>";
    this.editFormOptions.forEach(items => {
      var items_n = items.filter(item => !item.hidden && !this.base.checkEmpty(item.title));
      if (items_n == null || items_n.length <= 0) {
        return;
      }
      var idx = 0;
      html += "<tr>"
      items_n.forEach(item => {
        idx += 1;
        if (!item.hidden && !this.base.checkEmpty(item.title)) {
          var value = this.editFormFields[item.field];
          var text = this.editFormFields[item.field];
          text = this.changePrintRowData(item, value, text)
          //计算跨行
          var colspan = ""
          if (item.colSize == 12) {
            colspan = columnNum * 2 - 1;
          }
          else if (idx == items_n.length && columnNum > idx) {
            colspan = (columnNum - idx) * 2 + 1
          }
          if (colspan != "") {
            colspan = "colspan='" + colspan + "'";
          }
          html += "<td class='tbody_td_label'>" + item.title + "</td><td " + colspan + ">" + text + "</td>"
        }
      })
      html += "<tr/>"
    })
    html += "</table>";

    //如果有明细表的话，加上明细table
    if (this.$refs.detail != null && this.$refs.detail.columns != null) {
      html += this.changePrintTableData(this.$refs.detail.columns, this.$refs.detail.rowData, this.$refs.detail, 'detail')
    }

    //自定义table-非代码生成
    ["modelFooter"].forEach(model => {
      if (this.$refs[model] != null && this.$refs[model].$refs != null) {
        var refs = this.$refs[model].$refs;
        for (let key in refs) {
          var table = refs[key];
          if (table.columns == null || table.columns.length <= 0 || key.indexOf('select') == 0) {//排除自定义弹窗组件
            continue;
          }
          var data = table.rowData;
          if (data == null || data.length <= 0) {
            data = table.tableData;
          }
          html += this.changePrintTableData(table.columns, data, table)
        }
      }
    })
    return html
  },
  changePrintRowData(item, value, text) {
    if (value == null || value == '' || value == undefined) {
      return ""
    }
    if (item.type == "date") {
      text = value.split(value.indexOf('T') > -1 ? 'T' : ' ')[0]
    }
    else if (item.type == "datetime") {
      text = value.replaceAll('T', ' ')
    }
    else if (item.type == "file") {
      text = "";
      if (!Array.isArray(value)) {
        value = value.split(',')

      }
      value.forEach(x => {
        text += (this.base.checkEmpty(x.name) ? x.split('/')[x.split('/').length - 1] : x.name) + "<br/>"
      })
    }
    else if (item.type == "img") {
      text = "";
      if (!Array.isArray(value)) {
        value = value.split(',')

      }
      value.forEach(x => {
        var path = this.http.ipAddress + (this.base.checkEmpty(x.path) ? x.split('/')[x.split('/').length - 1] : x.path);
        text += "<img src='http://oa.nddrcy.com/img/logo2.1f76f00f.png' />" + "<br/>"
      })
    }
    else if (item.type == "esign") {
      text = "<img src='http://oa.nddrcy.com/img/logo2.1f76f00f.png' width='500' />";//图片不存在会报错导致打印失败
    }
    else if (!this.base.checkEmpty(item.dataKey)) {
      //表单页-数据源绑定，根据数据源获取值
      var data = item.orginData
      if (data == null || data.length <= 0) {
        data = item.data;
      }
      var filter = data.filter(x => parseInt(x.key) == parseInt(Array.isArray(value) ? value[value.length - 1] : value));
      if (filter == null || filter.length <= 0) {
        text = ""
      } else {
        text = filter[0].value
      }
    }
    else if (!this.base.checkEmpty(item.bind)) {
      //table页-数据源绑定，根据数据源获取值
      var data = item.orginData
      if (data == null || data.length <= 0) {
        data = item.data;
      }
      if (data == null || data.length <= 0) {
        data = item.bind.orginData
        if (data == null || data.length <= 0) {
          data = item.bind.data;
        }
      }
      if (item.edit != null && !this.base.checkEmpty(item.edit.type)) {
        if (["treeSelect", "selectList", "checkBox"].indexOf(item.edit.type) > -1) {
          //多选
          text = ""
          var texts = [];
          value.forEach(v => {
            var filter = data.filter(x => parseInt(x.key) == parseInt(v));
            if (filter != null && filter.length > 0) {
              texts.push(filter[0].value)
            }
          });
          text = texts.join('<br/>')
        }
        else {
          var filter = data.filter(x => parseInt(x.key) == parseInt(Array.isArray(value) ? value[value.length - 1] : value));
          if (filter == null || filter.length <= 0) {
            text = ""
          } else {
            text = filter[0].value
          }
        }
      }
      else {
        var filter = data.filter(x => parseInt(x.key) == parseInt(Array.isArray(value) ? value[value.length - 1] : value));
        if (filter == null || filter.length <= 0) {
          text = ""
        } else {
          text = filter[0].value
        }
      }

    }
    return text
  },
  changePrintTableData(columns, rowData, refTable, type) {
    var tableCnName = this.table.cnName
    var captionName = type == 'detail' ? this.detail.cnName : ''
    var html = "<table>"
    html += "<caption>" + (type == 'detail' ? this.detail.cnName : refTable.title ? refTable.title : '') + "</caption>"
    html += "<thead><tr>";
    var detailFields = []
    columns.forEach(item => {
      if (!item.hidden && !this.base.checkEmpty(item.title) && item.field != "操作") {
        html += "<td class='thead_td'>" + item.title + "</td>"
        detailFields.push(item)
      }
    })
    html += "</tr></thead>";
    html += "<tbody>";
    rowData.forEach(row => {
      var tdHtml = ""
      detailFields.forEach(item => {
        if (tableCnName == "离职交接" && ['MatterContent', 'Dept'].indexOf(item.field) > -1) {
          var matter = this.$refs.modelFooter.tableMatterData.filter(s => s.MatterType == row.MatterType)[0];
          tdHtml += "<td>" + matter[item.field] + "</td>"
          return
        }
        var value = row[item.field];
        var text = row[item.field];
        tdHtml += "<td>" + this.changePrintRowData(item, value, text) + "</td>"
      })

      html += "<tr class='tr_left'>" + tdHtml + "</tr>"
    })
    html += "</tbody>";
    html += "</table>";
    return html;
  },
  getAllChildrenText(refs, node, html) {
    node.children.forEach(x => {
      //判断是否是table
      var tableName = ""
      x.classList.forEach(c => {
        for (var k in refs) {
          if (k == c) {
            tableName = k
          }
        }
      })
      if (tableName == "") {
        html += "<p>" + x.innerHTML + "</p>"
        return;
      }
      var table = refs[tableName]
      if (table != null && table.columns != null && table.columns.length > 0) {
        var data = table.rowData;
        if (data == null || data.length <= 0) {
          data = table.tableData;
        }
        html += this.changePrintTableData(table.columns, data, table)
      }
      //this.getAllChildrenText(refs, x, html)
    })
    return html
  },
  confirm(obj, callback) {
    this.$confirm(obj.title ?? "确定进行该操作吗？", '警告', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      center: true
    }).then(() => {
      return callback(true)
    }).catch((e) => {
      //e == 'cancel'
      //e == 'close' 右上角X的执行逻辑
      return callback(false)
    })
  },
  print(d) {
    var href = location.origin;
    if (href.indexOf('localhost') > -1) {
      href = 'http://localhost:9992'
    }
    var style = "@page {size: auto A4 landscape;margin:0;}";//不打印页眉页脚、去除边距
    style += "@media print { body {webkit-print-color-adjust: exact;font-size:13px;}}";//
    printjs({
      printable: d.html,
      type: 'raw-html',
      documentTitle: d.documentTitle || "银典城市运营集团",
      header: '',
      base64: true,
      scanStyle: false,
      css: href + '/css/print.css',
      //headerStyle: 'font-weight:400;text-align:center;',
      style: style,
      honorColor: true, // 是否打印彩色文本
      targetStyles: ['*'] // 允许打印所有样式属性
    })
  },
  resetDetailTableColumns() {
    //修复问题：先点击审批后的数据，再新增或修改数据，子表无法操作
    if (!this.hasDetail) {
      return;
    }
    //如果不处于审批状态或者审批状态为退回，子表还可继续操作
    var isAudit = true
    if (this.continueAdd) {
      isAudit = false;
    }
    else {
      if (!this.currentRow.hasOwnProperty("AuditStatus")) {
        isAudit = false
      }
      else {
        if (this.currentRow.AuditStatus == null || this.currentRow.AuditStatus == 4) {
          isAudit = false
        }
      }
    }
    if (!isAudit) {
      this.detailOptions.columns.forEach(x => {
        if (!x.hidden && x.edit) {
          x.readonly = false
        }
      })
      //重置多子表tabs
      if (this.details != null && this.details.length > 1) {
        this.details.forEach((item, index) => {
          item.columns.forEach(x => {
            if (!x.hidden && x.edit) {
              x.readonly = false
            }
          })
        })
      }
    }
  },
  getDetailsOptions() {
    return {
      //弹出框从表(明细)对象
      //从表配置
      buttons: [], //弹出框从表表格操作按钮,目前有删除行，添加行，刷新操作，如需要其他操作按钮，可在表对应的.js中添加
      cnName: '', //从表名称
      key: '', //从表主键名
      data: [], //数据源
      columns: [], //从表列信息
      edit: true, //明细是否可以编辑
      single: false, //明细表是否单选
      load: false, //
      delKeys: [], //当编辑时删除当前明细的行主键值
      url: '', //从表加载数据的url
      pagination: { total: 0, size: 100, sortName: '' }, //从表分页配置数据
      height: 0, //默认从表高度
      textInline: true, //明细表行内容显示在一行上，如果需要换行显示，请设置为false
      doubleEdit: true, //使用双击编辑
      clickEdit: false, //是否开启点击单元格编辑，点击其他行时结束编辑
      currentReadonly: false, //当前用户没有编辑或新建权限时，表单只读(可用于判断用户是否有编辑或新建权限)
      //开启编辑时
      beginEdit: (row, column, index) => {
        return true;
      },
      //结束编辑前
      endEditBefore: (row, column, index) => {
        return true;
      },
      //结束编辑后
      endEditAfter: (row, column, index) => {
        return true;
      },
      columnIndex: false, //2020.11.01明细是否显示行号
      ck: true, //2020.11.01明细是否显示checkbox
      paginationHide: false,//是否隐藏分页
      other: {},
    }
  },
  getDetailsButtons() {
    return [{
      "name": "添加",
      "plain": true,
      "icon": "el-icon-plus",
      "value": "add",
      "type": "success",
      hidden: false,
    },
    {
      "name": "删除",
      "plain": true,
      "icon": "el-icon-delete",
      "value": "del",
      "type": "danger",
      hidden: false,
    },
    {
      "name": "导入",
      "plain": true,
      "icon": "el-icon-upload2",
      "value": "import",
      "type": "primary",
      hidden: true,
    },
    {
      "name": "导出",
      "plain": true,
      "icon": "el-icon-download",
      "value": "export",
      "type": "",
      hidden: true,
    }]
  },
  tabsTableBtnClick(item, index, btnItem, btnIndex) {
    if (btnItem.onClick) {
      //重写了方法，不执行下面的默认操作
      btnItem.onClick()
      return;
    }
    var detail = this.$refs["detail" + item.tableIndex][0]
    var options = this.details[index].detailOptions
    if (btnItem.value == "add") {
      detail.addRow({});
      return;
    }
    else if (btnItem.value == "del") {
      //获取删除行
      var selectRows = detail.getSelected()
      selectRows.forEach(r => {
        if (!this.base.checkEmpty(r[options.key])) {
          this.details[index].detailOptions.delKeys.push(r[options.key])
        }
      })
      detail.delRow();
    }
    else if (btnItem.value == "import") {
      var table = this.details[index].table
      this.upload.url = `${this.http.ipAddress}api/${table}/${this.const.IMPORT}?table=1`;
      this.upload.template.url = `${this.http.ipAddress}api/${table}/${this.const.DOWNLOADTEMPLATE}`;
      // 定义下载模板的文件名
      this.upload.template.fileName = options.cnName;
      this.upload.excel = true;
    }
    else if (btnItem.value == "export") {
      this.exportDetail(this.details[index])
    }
  },
  getEditOption(field) {
    let option;
    this.editFormOptions.forEach(items => {
      items.forEach(item => {
        if (item.field == field) {
          option = item
        }
      })
    })
    return option
  }
};
import customColumns from './ViewGridCustomColumn.js';
import printjs from 'print-js'
import domToImage from 'dom-to-image'
//合并扩展方法
methods = Object.assign(methods, detailMethods, serviceFilter, customColumns);
export default methods;
