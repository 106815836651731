let base = {
  addDays(date, days) {
    //给指定日期增加天数
    if (!days) {
      return date;
    }
    let dateArr = date.split(' ');
    date = new Date(new Date(date).setDate(new Date(date).getDate() + days));
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    var day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    date = year + '-' + month + '-' + day;
    if (dateArr.length == 1) {
      return date;
    }
    return date + ' ' + dateArr[1];
  },
  //获取当前时间，time是否带时分秒
  getDate(time) {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let datetime =
      year +
      '-' +
      (month < 10 ? '0' + month : month) +
      '-' +
      (day < 10 ? '0' + day : day);

    if (!time) {
      return datetime;
    }

    let hour = date.getHours();
    let minutes = date.getMinutes();
    let second = date.getSeconds();

    return (
      datetime +
      '' +
      ' ' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (second < 10 ? '0' + second : second)
    );
  },
  isPhone(val) {
    return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(val);
  },
  isDecimal(val) {
    return /(^[\-0-9][0-9]*(.[0-9]+)?)$/.test(val);
  },
  isNumber(val) {
    return /(^[\-0-9][0-9]*([0-9]+)?)$/.test(val);
  },
  isMail(val) {
    return /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/.test(val);
  },
  isUrl(url) {
    return this.checkUrl(url);
  },
  checkUrl(url) {
    // url= 协议://(ftp的登录信息)[IP|域名](:端口号)(/或?请求参数)
    var strRegex =
      '^((https|http|ftp):/{2,3})?' + // 修改为允许2到3个斜杠
      "(([\\w_!~*'()\\.&=+$%-]+: )?[\\w_!~*'()\\.&=+$%-]+@)?" + // ftp的user@  可有可无
      '(([0-9]{1,3}\\.){3}[0-9]{1,3}' + // IP形式的URL- 3位数字.3位数字.3位数字.3位数字
      '|' + // 允许IP和DOMAIN（域名）
      '(localhost)|' + // 匹配localhost
      "([\\w_!~*'()-]+\\.)*" + // 域名- 至少一个[英文或数字_!~*\'()-]加上.
      '\\w+\\.' + // 一级域名 -英文或数字  加上.
      '[a-zA-Z]{1,6})' + // 顶级域名- 1-6位英文
      '(:[0-9]{1,5})?' + // 端口- :80 ,1-5位数字
      '((/?)|' + // url无参数结尾 - 斜杆或这没有
      "(/[\\w_!~*'()\\.;?:@&=+$,%#-]+)+/?)$"; // 请求参数结尾- 英文或数字和[]内的各种字符
    var re = new RegExp(strRegex, 'i'); // i不区分大小写
    // 将url做uri转码后再匹配，解除请求参数中的中文和空字符影响
    if (re.test(encodeURI(url))) {
      return true;
    }
    return false;
  },
  matchUrlIp(url, ip) {
    // url使用是否使用的当前ip
    if (!url || !ip) {
      return false;
    }
    return url.indexOf(ip.replace('https://', '').replace('http://', '')) >= 0;
  },
  getImgSrc(src, httpUrl) {
    if (this.isUrl(src)) {
      return src;
    }
    if (httpUrl) {
      return httpUrl + src;
    }
    return src;
  },
  previewImg(src, httpUrl) {
    // 图片预览，目前只支持单图片预览
    if (src && !this.isUrl(src) && httpUrl) {
      if (
        src.substr(0, 1) == '/' &&
        httpUrl.substr(httpUrl.length - 1, 1) == '/'
      ) {
        src = src.substr(1);
      }
      src = httpUrl + src;
    }
    let id = 'vol-preview';
    let $div = document.getElementById(id);
    if (!$div) {
      $div = document.createElement('div');
      $div.setAttribute('id', 'vol-preview');
      let $mask = document.createElement('div');
      $mask.style.position = 'absolute';
      $mask.style.width = '100%';
      $mask.style.height = '100%';
      $mask.style.background = 'black';
      $mask.style.opacity = '0.6';
      $div.appendChild($mask);
      $div.style.position = 'fixed';
      $div.style.width = '100%';
      $div.style.height = '100%';
      // $div.style.overflow = "scroll";
      $div.style.top = 0;
      $div.style['z-index'] = 9999999;
      let $img = document.createElement('img');
      $img.setAttribute('class', 'vol-preview-img');
      $img.style.position = 'absolute';
      $img.style.top = '50%';
      $img.style.left = '50%';
      $img.style['max-width'] = '90%';
      $img.style['max-height'] = '90%';
      $img.style.transform = 'translate(-50%,-50%)';
      // $img.src = src;
      $img.setAttribute('src', src);
      $div.appendChild($img);
      $div.addEventListener('click', function () {
        this.style.display = 'none';
      });
      document.body.appendChild($div);
      return;
    }
    let $img1 = document.body
      .appendChild($div)
      .querySelector('.vol-preview-img');
    // img.src = src;
    $img1.setAttribute('src', src);
    $div.style.display = 'block';
  },
  // 下载文件 $element 标签, url完整url, fileName 文件名, header 以key/value传值
  // backGroundUrl 后台url，如果后台url直接从后台下载，其他全部通过点击a标签下载
  dowloadFile(url, fileName, header, backGroundUrl) {
    backGroundUrl = location.origin;
    if (url.indexOf('/') > 0 && url.indexOf("http") != 0) {
      backGroundUrl += "/"
    }
    if (!url) return alert('此文件没有url不能下载');
    if (!this.isUrl(url)) {
      url = backGroundUrl + url;
    }
    if (this.checkOfficeViewType(fileName)) {
      var url_n = encodeURIComponent(url)
      //window.open('#/OfficeView?url=' + url_n, "_blank")
      ////使用微软的office在线预览
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url_n, "_blank")
      return
    }
    window.open(url);
  },
  downloadImg(data) {
    if (!data.url || !data.callback || typeof data.callback !== 'function') {
      return;
    }
    // url, backGroundUrl, header, callback
    if (
      this.isUrl(data.url) &&
      !this.matchUrlIp(data.url, data.backGroundUrl)
    ) {
      return data.url;
    }
    // 通过后台api服务器下载
    if (!this.isUrl(data.url)) {
      if (!this.isUrl(data.backGroundUrl + data.url)) {
        return;
      }
      data.url = data.backGroundUrl + data.url;
    }
    var xmlResquest = new XMLHttpRequest();
    xmlResquest.open('get', data.url, true);
    xmlResquest.responseType = 'blob';
    xmlResquest.setRequestHeader('Content-Type', 'application/json');
    if (data.header && typeof data.header === 'object') {
      for (const key in data.header) {
        xmlResquest.setRequestHeader(key, data.header[key]);
      }
    }
    xmlResquest.onload = function () {
      if (this.status == 200) {
        var blob = this.response;
        callback(window.URL.createObjectURL(blob));
      }
    };
    xmlResquest.send();
  },
  // 2020.06.01增加通用方法，将普通对象转换为tree结构
  // data数据格式[
  //     { name: 'tree1', id: 1, parentId: 0 },
  //     { name: 'tree2', id: 2, parentId: 0 }]

  // 1、id与parentId这两个字段必须有
  // 2、树形tree需要注意Id与parentId循环依赖的问题
  // 3、callback每次生成一新的节点的时回调的方法

  convertTree(data, callback) {
    var treeIds = [];
    var root_data = [];
    if (data.length > 100) {
      data = JSON.parse(JSON.stringify(data));
    }
    data.forEach((x) => {
      // if (!x.children) {
      //   x.children = []
      // }
      if (
        !x.hidden &&
        x.id !== undefined &&
        x.id !== x.parentId &&
        !data.some((s) => {
          return x.parentId == s.id;
        })
      ) {
        x.isRoot = true;
        callback && callback(x, data, true, treeIds);
        root_data.push(x);
        getTree(x.id, x, data, callback, treeIds);
      } else {
        callback && callback(x, data, true, treeIds);
      }
    });
    var exceptionNodes = data.filter((f) => {
      return treeIds.indexOf(f.id) == -1 && !f.hidden;
    });

    root_data.push(...exceptionNodes);
    return root_data;
  },
  getTreeAllParent(id, data) {
    // 获取某个节点的所有父节点信息2020.11.01
    var nodes = [];
    if (!(data instanceof Array)) {
      return nodes;
    }
    if (data.length > 100) {
      data = JSON.parse(JSON.stringify(data));
    }
    data.forEach((x) => {
      if (x.id === x.parentId) {
        x.parentId = 0;
      } else if (data.some((c) => c.parentId === x.id && c.id === x.parentId)) {
        x.parentId = 0;
      }
    });

    var _child = data.find((x) => {
      return x.id === id;
    });
    if (!_child) {
      return [];
    }
    nodes.push(_child);
    var _parentIds = [_child.parentId];
    for (let index = 0; index < _parentIds.length; index++) {
      var _node = data.find((x) => {
        return x.id === _parentIds[index] && x.id !== x.parentId;
      });
      if (!_node) {
        return nodes;
      }
      _parentIds.push(_node.parentId);
      nodes.unshift(_node);
    }
    return nodes;
  },
  //获取所有节点的子节点
  // data数据格式[
  //     { name: 'tree1', id: 1, parentId: 0 },
  //     { name: 'tree2', id: 2, parentId: 0 }]
  getTreeAllChildren(id, data) {
    //递归获取某个节点的所有子节点信息
    var nodes = [];
    if (!(data instanceof Array)) {
      return nodes;
    }
    if (data.length > 100) {
      data = JSON.parse(JSON.stringify(data));
    }
    var _child = data.find((x) => {
      return x.id === id;
    });
    if (!_child) {
      return [];
    }
    nodes.push(_child);
    var _parentIds = [_child.id];
    for (let index = 0; index < _parentIds.length; index++) {
      data.forEach((_node) => {
        if (
          _node.parentId === _parentIds[index] &&
          _node.parentId !== _node.id
        ) {
          _parentIds.push(_node.id);
          nodes.unshift(_node);
        }
      });
    }
    return nodes;
  },
  //获取所有子节点的id
  // data数据格式[
  //     { name: 'tree1', id: 1, parentId: 0 },
  //     { name: 'tree2', id: 2, parentId: 0 }]
  getTreeAllChildrenId(id, data) {
    return this.getTreeAllChildren(id, data).map((c) => {
      return c.id;
    });
  },
  getTreeAllParentId(id, data) {
    return this.getTreeAllParent(id, data).map((c) => {
      return c.id;
    });
  },
  getTipTag(note) {
    return [
      {
        colSize: 12,
        render: (h) => {
          return h(
            'div',
            {
              style: {
                display: 'flex',
                'margin-bottom': '-4px',
                'line-height': '20px',
                'margin-top': '-10px',
                'padding-bottom': '5px',
                'border-bottom': '1px solid #eee'
              },
              name: "div_" + note
            },
            [
              h('div', {
                style: {
                  height: '19px',
                  background: '#2dced9',
                  width: '9px',
                  'border-radius': '10px'
                }
              }),
              h(
                'div',
                {
                  style: {
                    'padding-left': '6px',
                    'font-weight': 'bold',
                    'font-size': '13px'
                  }
                },
                '' + note + ''
              )
            ]
          );
        }
      }
    ]
  },
  getIntArray(str, splitChar) {
    if (str == '' || str == null || str == undefined) {
      str = "0";
    }
    var dataStrArr = str.toString().split(splitChar);
    var dataIntArr = [];
    dataStrArr.forEach(function (data, index, arr) {
      dataIntArr.push(+data);
    });
    return dataIntArr;
  },
  getStringArray(str, splitChar) {
    if (str == '' || str == null || str == undefined) {
      return null
    }
    return str.toString().split(splitChar);
  },
  getTagHtml(note, zd) {
    var html = '<view style="display: flex; line-height: 20px; margin: 20px 0 5px 0; padding-bottom: 5px; border-bottom: 1px solid rgb(238, 238, 238);">';
    html += '<div style="height: 19px; background: rgb(45, 206, 217); width: 9px; border-radius: 10px;"></div>';
    html += '<div style="padding-left: 6px; font-weight: bold; font-size: 13px;width: 99%;">' + note + '</div>';
    if (zd == 1) {
      html += '<i class="el-icon-arrow-down" style="width: 20px;cursor:pointer"></i>';
    }
    else if (zd == 2) {
      html += '<i class="el-icon-arrow-up" style="width: 20px;;cursor:pointer"></i>';
    }

    html += '</view>';
    return html;
  },

  formatDate(date, fmt) {
    //参数1: date 对象
    //参数2: fmt: 'yyyy', 'yyyy-MM-dd', 'yy-MM-dd', 'yyyy-MM-dd hh:mm:ss', ...(按需求自己定义格式，默认是'yyyy-MM-dd hh:mm:ss')

    if (date == '') {
      return new Date();
    } else {
      date = new Date(date);
    }


    //if (!fmt) fmt = "yyyy-MM-dd HH:mm:ss";

    if (!date || date == null) return null;
    var o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours() < 12 ? "上午" + date.getHours().toString() : "下午" + (date.getHours() - 12).toString(), // 小时
      'H+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      'S': date.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1 || RegExp.$1 == 'hh') ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
    return fmt
  },
  getAge(strBirthday) {
    //传入值必须是yyyy-MM-dd 的格式
    var returnAge;

    var strBirthdayArr = strBirthday.split("-");
    var birthYear = strBirthdayArr[0];
    var birthMonth = strBirthdayArr[1];
    var birthDay = strBirthdayArr[2];

    var d = new Date();
    var nowYear = d.getFullYear();
    var nowMonth = d.getMonth() + 1;
    var nowDay = d.getDate();

    if (nowYear == birthYear) {
      returnAge = 0;//同年 则为0岁
    }
    else {
      var ageDiff = nowYear - birthYear; //年之差
      if (ageDiff > 0) {
        if (nowMonth == birthMonth) {
          var dayDiff = nowDay - birthDay;//日之差
          if (dayDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        } else {
          var monthDiff = nowMonth - birthMonth;//月之差
          if (monthDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        }
      }
      else {
        returnAge = -1;//出生日期不能大于今天
      }
    }
    return returnAge;
  },
  checkEmpty(str) {
    if (str == null || str == '' || str == 0 || str == undefined) {
      return true;
    }
    return false
  },
  checkEmpty1(str) {
    if (str == null || str == '' || str == undefined) {
      return true;
    }
    return false
  },
  checkHasEdit(row) {
    //AuditStatus=4，退回状态，需要可操作界面
    if (row.AuditStatus == null || row.AuditStatus == 4) {
      return true;
    }
    return false;
  },

  //计算本年的周数
  getYearWeek() {
    var endDate = new Date()
    //本年的第一天
    var beginDate = new Date(endDate.getFullYear(), 0, 1);
    //星期从0-6,0代表星期天，6代表星期六
    var endWeek = endDate.getDay();
    if (endWeek == 0) endWeek = 7;
    var beginWeek = beginDate.getDay();
    if (beginWeek == 0) beginWeek = 7;
    //计算两个日期的天数差
    var millisDiff = endDate.getTime() - beginDate.getTime();
    var dayDiff = Math.floor((millisDiff + (beginWeek - endWeek) * (24 * 60 * 60 * 1000)) / 86400000);
    return Math.ceil(dayDiff / 7) + 1;
  },
  //乘法
  accMul(arg1, arg2) {
    let m = 0,

      s1 = arg1.toString(),
      s2 = arg2.toString()
    try {
      m += s1.split('.')[1].length
    } catch (e) { }
    try {
      m += s2.split('.')[1].length
    } catch (e) { }
    return (
      (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
      Math.pow(10, m)
    )
  },
  //减法：
  accSubtr(arg1, arg2) {
    let r1, r2, m, n
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    //动态控制精度长度
    n = r1 >= r2 ? r1 : r2
    return ((arg1 * m - arg2 * m) / m).toFixed(2)//.toFixed(n)
  },
  //除法
  accDiv(arg1, arg2) {
    let t1 = 0,
      t2 = 0,
      r1,
      r2
    try {
      t1 = arg1.toString().split('.')[1].length
    } catch (e) { }
    try {
      t2 = arg2.toString().split('.')[1].length
    } catch (e) { }
    r1 = Number(arg1.toString().replace('.', ''))
    r2 = Number(arg2.toString().replace('.', ''))
    return (r1 / r2) * Math.pow(10, t2 - t1)
  },
  //加法
  accAdd(arg1, arg2) {
    let r1, r2, m
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
  },
  shareTableConfig() {
    var config = []
    config.push({
      tableName: 'Form_TempDisinfectRecord',
      config: [{ name: '冷库温度控制记录', url: 'Form_TempDisinfectRecord' }, { name: '热风消毒柜消毒记录', url: 'Form_DisinfectRecord' }, { name: '蒸汽消毒库消毒记录', url: 'Form_SteamDisinfectRecord' },
      { name: '消毒库温度控制记录', url: 'Form_DisinfectWareHourseTempRecord' }]
    })
    config.push({
      tableName: 'Sys_DeviceCheck',
      config: [{ name: '设备检查', url: 'Sys_DeviceCheck' },
      { name: '设备盘点', url: 'Sys_DeviceInventory' }]
    })
    config.push({
      tableName: 'YD_JXExam',
      config: [{ name: '绩效考核', url: 'YD_JXExam' },
      { name: '市场拓展人员绩效考核', url: 'Sys_ProjectBaseMarketerJXExam' }]
    })
    return config
  },
  getShareTableConfig(row) {
    var row_ = {
      WorkTable: row.WorkTable,
      WorkTableName: row.WorkTableName || row.WorkName
    }
    var shareTable = this.shareTableConfig()
    var WorkTable = row.WorkTable;
    var filter = shareTable.filter(x => x.tableName == WorkTable)
    if (filter != null && filter.length > 0) {
      var f = filter[0].config.filter(x => x.name == row.WorkName)
      if (f != null && f.length > 0) {
        row_.WorkTable = f[0].url
        row_.WorkTableName = f[0].name
      }
    }
    return row_
  },
  base64ToFile(base64, filename, filesuffix = "") {
    let arr = base64.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let suffix = mime.split('/')[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    // new File返回File对象 第一个参数是 ArraryBuffer 或 Bolb 或Arrary 第二个参数是文件名
    // 第三个参数是 要放到文件中的内容的 MIME 类型
    if (mime == "application/octet-stream") {
      if (!this.checkEmpty(filesuffix)) {
        if (filesuffix == "webm") {
          mime = "video/webm"
          suffix = "webm"
        } else if (filesuffix == "mp3") {
          mime = "audio/mp3"
          suffix = "mp3"
        }
      } else {
        mime = "video/webm"
        suffix = "webm"
      }
    }

    var userAgent = navigator.userAgent;
    var isiOS = !!userAgent.match(
      /\(i[^;]+;( U;)? CPU.+Mac OS X/
    ); //ios终端
    if (isiOS) {
      var blob = new Blob([u8arr], {
        type: mime
      });
      blob.lastModifiedDate = new Date()
      blob.name = `${filename}.${suffix}`
      return blob;
    }
    return new File([u8arr], `${filename}.${suffix}`, {
      type: mime,
      input: true
    });
  },
  createCode() {
    var code = '';
    //设置长度，这里看需求，我这里设置了4
    var codeLength = 4;
    //设置随机字符
    var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
    //循环codeLength 我设置的4就是循环4次
    for (var i = 0; i < codeLength; i++) {
      //设置随机数范围,这设置为0 ~ 36
      var index = Math.floor(Math.random() * 9);
      //字符串拼接 将每次随机的字符 进行拼接
      code += random[index];
    }
    //将拼接好的字符串赋值给展示的code
    return code;
  },
  checkOfficeViewType(url) {
    var types = ['.doc', '.xls', '.docx', '.xlsx', '.pptx']
    var ok = false
    types.forEach(type => {
      if (url.indexOf(type) > -1) {
        ok = true
      }
    })
    return ok;
  },
  checkFileType(file) {
    var typeName = file.split('.')[file.split('.').length - 1].toLowerCase()
    if (['png', 'jpg', 'jpeg', 'bmp'].includes(typeName)) {
      return "image"
    } else if (['mp4', 'webm', 'ogg'].includes(typeName)) {
      return "video"
    } else if (['pdf'].includes(typeName)) {
      return "pdf"
    } else {
      return "word"
    }
  },
  downloadBlob(blob, fileName) {
    if ('download' in document.createElement('a')) {
      // 非IE下载
      const elink = document.createElement('a');
      elink.download = fileName;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    } else {
      // IE10+下载
      navigator.msSaveBlob(blob, fileName);
    }
  }
};

export default base;


// 2020.06.01增加通用方法，将普通对象转换为tree结构
function getTree(id, node, data, callback, treeIds) {
  if (treeIds.indexOf(id) == -1) {
    treeIds.push(id);
  }
  data.forEach((x) => {
    if (!x.hidden && x.parentId == id) {
      if (!node.children) node.children = [];
      callback && callback(x, node, false);
      //2023.8.25 新加排重
      var find = node.children.find(s => s.id == x.id)
      if (find == null) {
        node.children.push(x);
      }
      getTree(x.id, x, data, callback, treeIds);
    }
  });
}
