<template>

    <VolBox v-model="model" :lazy="true" title="选择员工" :height="540" :width="1000" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="员工姓名、员工账户" style="width: 250px" v-model="empName" @keyup.enter="search()" />
            <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i
                    class="el-icon-search"></i>搜索</el-button>
        </div>
        
        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="true"
            :max-height="380" :url="url" :index="true" :single="true" :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="mini" type="primary" @click="addRow()"><i class="el-icon-plus"></i>添加选择的数据</el-button>
                <el-button size="mini" @click="model = false;closeContractBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>

</template>
<script>
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";

export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
    },
    props: {
        selectRow: Object
    },
    data() {
        return {
            model: false,
            empName: "", //查询条件字段
            jobLabel: "",//职位标签
            jobLabelOptions: [],//职位标签选项
            defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            url: "api/Sys_ContractManage/getSelectContractList",//加载数据的接口
            columns: [
                { field: "EmpID", title: "员工ID", width: 90, hidden: true },
                { field: "EmpName", title: "员工", width: 80 },
                { field: "DeptID", title: "所属部门", width: 100, type: 'int', bind: { key: 'dept-tree', data: [] } },
                { field: "PositionID", title: "担任职位", width: 100, type: 'int', bind: { key: '职位-tree', data: [] } },
                { field: "HireDate", title: "入职日期", width: 100 ,type:'date'},
                { field: "AssertDate", title: "合同开始日期", width: 100 ,type:'date'},
                { field: "ExpireDate", title: "合同期满日期", width: 100 ,type:'date'},
                { field: "ContractType", title: "合同类型", width: 90, bind: { key: '劳动合同类型', data: [] }  },
                { field: "IfRenewal", title: "签订方式", width: 80 , bind: { key: '合同签订方式', data: [] } },
                {
                    field: "opt",
                    title: "操作",
                    width: 70,
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.$emit("parentCall", ($parent) => {
                                        $parent.SelectContract(row);
                                    })
                                    // this.selectEmpCall(row)
                                    this.handleClick(row);
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            pagination: {}, //分页配置，见voltable组件api
        };
    },
    methods: {

        load(){
          //console.log(this.$store.getters.data().Emp.EmpID);
         



            this.$refs.mytable.load();
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },
        search() {
            //点击搜索
            this.$refs.mytable.load();
        },
        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.$emit("parentCall", ($parent) => {
                $parent.SelectContract(rows[0]);
            })
            this.handleClick(rows[0]);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
            //查询前，设置查询条件
            if (this.empName) {
                params.wheres.push({ name: "EmpName", value: this.empName });
            }
            //var EmpID = this.$store.getters.data().Emp.EmpID;
            // if (EmpID !== undefined) {
            //         params.wheres.push({ name: 'EmpID', value: EmpID })
            //     }
            return true;
        },
        // selectEmpCall(row){
        //     this.$emit("selectEmpCall", row);
        // }
    },
    emits: ['ContractCallBack','CloseContractBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('ContractCallBack', item)
        }
        const closeContractBox = (item) => {
            emit('CloseContractBox', item)
        }
        return {
            handleClick,
            closeContractBox
        }
    }
};



</script>